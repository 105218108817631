import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
const myrequirementsStyle = theme => ({
    mainContainer: {
        borderRadius: '10px',
        boxShadow: '0 4px 16px 0 rgba(0,0,0,0.5)',
    },
    OrderStatusApproved: {
        backgroundColor: '#dff0d8',
        color: '#3c763d',
        padding: "5px 10px",
        borderRadius: '10%',
        fontSize: '14px',
        fontWeight: '800',
        fontFamily: 'Nunito, sans-serif',
    }, OrderStatusAssigned: {
        backgroundColor: theme.palette.primary.main,
        color: '#e8eaf6',
        padding: "5px 10px",
        borderRadius: '10%',
        fontSize: '14px',
        fontWeight: '800',
        fontFamily: 'Nunito, sans-serif',
    }, OrderStatusRejected: {
        backgroundColor: '#f2dede',
        color: '#a94442',
        padding: "5px 10px",
        borderRadius: '10%',
        fontSize: '14px',
        fontWeight: '800',
        fontFamily: 'Nunito, sans-serif',
    }, OrderStatusPlaced: {
        backgroundColor: '#e0e0e0',
        color: '#212121',
        padding: "5px 10px",
        borderRadius: '10%',
        fontSize: '14px',
        fontWeight: '800',
        fontFamily: 'Nunito, sans-serif',
    },
});
const combinedStyles = combineStyles(commonStyle, myrequirementsStyle);
export default combinedStyles;
