const commonStyle = theme => ({
  fab: {
    position: 'fixed',
    bottom: window.innerWidth <= 920 ? theme.spacing(2) : theme.spacing(2),
    right: window.innerWidth <= 920 ? theme.spacing(2) : theme.spacing(2),
  },
  main: {
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    padding: '12px 12px',
    // background: `url(${"../assets/globe_bg.jpg"})`,
    // backgroundSize: 'cover',
    // backgroundPosition: 'center',
    // width: '100%',
    // height: '100%',
    // backgroundColor: '#fff',
    flex: 1,
    height: '100vh',

    // marginBottom: '50px',
  },
  commontitle: {
    fontFamily: 'Nunito, sans-serif', fontWeight: 700,
    fontSize: '30px',
    lineHeight: 1.13,
    letterSpacing: -0.5,
    color: 'red',
  },
  subtitle: {
    fontFamily: 'Nunito, sans-serif', fontWeight: 400,
    fontSize: '14px',
    lineHeight: 1.5,
    color: theme.palette.textColor,
    marginBottom: '2%'
  },
  linkText: {
    fontFamily: 'Nunito, sans-serif', fontWeight: 400,
    fontSize: '14px',
    lineHeight: 1.43,
    color: theme.palette.primary.main,
  },
  Menulink: {
    fontFamily: 'Nunito, sans-serif', fontWeight: 600,
    fontSize: '14px',
    lineHeight: 1.43,
    color: theme.palette.primary.main,
  },
  orGrid: {
    textAlign: 'center',
  },
  titleText: {
    fontFamily: 'Nunito, sans-serif', fontWeight: 400,
    fontSize: '18px',
    lineHeight: 1.13,
    letterSpacing: -0.5,
    color: theme.palette.primary.main,
    paddingBottom: '2%',
  },
  //AlertDialog for mobile View
  alerView:
  {
    padding: '5%',
    border: '1px solid #f1f1f5',
    borderTopWidth: '2%',
    borderRadius: '5% 5% 0% 0%',
    width: '100%',
    height: '375px',
    backgroundColor: '#fff',
    position: 'fixed',
    textAlign: 'center',
    bottom: '0',
    left: '0',
  },
});

export default commonStyle;
