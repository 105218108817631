/* eslint eqeqeq: 0 */
const Location = window.location.toString();

const Geturl = () => {
    if (Location.indexOf('chemisto.live') != -1) {
        return 'https://api.chemisto.live/'
    } else if (Location.indexOf('kmisto.com') != -1) {
        return 'https://api.chemisto.live/'
    } else {
        return 'https://chemisto.api.thestaging.cc/'
    }
}


export const URLs = {
    base: Geturl(),

    Gettoken: 'token',
    RegisterBrowserNotificationToken: 'API/Users/RegisterBrowserNotificationToken',
    ForgotPassword: 'API/Users/ForgotPassword',
    ChangePassword: 'API/Users/ChangePassword',
    SetPassword: 'API/Users/SetPassword',
    GetMyNotifications: 'API/Users/GetMyNotifications',
    VerifyToken: '/API/Users/VerifyToken',
    VerifyOTP: 'API/Users/VerifyOTP',
    ResendOTP: 'API/Users/ResendOTP',

    GetMyTenants: '/API/Tenant/GetMyTenants',
    ChangeMyTenant: '/API/Tenant/ChangeMyTenant',
    GetAllTenants: '/API/Tenant/GetAllTenants',
    AddStoreToTenant: '/API/Tenant/AddStoreToTenant',

    SearchMedicines: 'API/Inventory/SearchMedicines',
    GetCompanyForDropdown: 'API/Master/GetCompanyForDropdown',

    AddEditCart: 'API/Order/AddEditCart',
    DeleteCart: 'API/Order/DeleteCart',
    GetCartForDropdown: 'API/Order/GetCartForDropdown',
    GetMyOrders: 'API/Order/GetMyOrders',
    PlaceOrder: 'API/Order/PlaceOrder',
    OrderDetails: 'API/Order/OrderDetails',

    GetUserStores: 'API/Store/GetUserStores',
    AddEditStore: 'API/Store/AddEditStore',
    DeleteStore: 'API/Store/DeleteStore',
    GetStoreByID: 'API/Store/GetStoreByID',

    GetMyPickups: 'API/Store/GetMyPickups',
    AddEditPickup: 'API/Store/AddEditPickup'
}
