import { makeStyles } from '@material-ui/core/styles';

const selectDropDownStyle = makeStyles(theme => ({
  selectEmpty: {
    backgroundColor: theme.palette.inputBackground,
    border: 0,
    height: 48,
    width: '100%',
    // padding: '0 16px',
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: theme.palette.textColor
  }
}));

export default selectDropDownStyle;
