/*eslint-disable eqeqeq*/
import { Component } from 'react';
import authService from "../services/auth-service";

export const HTTPMethod = {
    get: "GET",
    post: "POST",
    put: "PUT",
    delete: "DELETE",
}

export const HTTPHeaderContentType = {
    json: "application/json",
    formURLEncoded: "application/x-www-form-urlencoded",
}

export class API extends Component {
    request(method, url, body) {
        return new Promise((resolve, reject) => {

            let headers = method.indexOf("token") == -1 ? this.requestHeaders() : this.requestTokenHeaders()
            fetch(url, { method: method, headers: headers, body: body })
                .then((response) => {

                    return response.json()

                })
                .then((responseJSON) => {
                    if (responseJSON.Message == "Authorization has been denied for this request.") {
                        authService.removeAuthData();
                        localStorage.clear()
                        // alert("Authorization has been denied for this request.")
                        window.location.replace('/');
                    } else {
                        resolve(responseJSON)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    requestTokenHeaders() {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }

    requestHeaders() {
        return {
            "Accept": HTTPHeaderContentType.json,
            "Content-Type": HTTPHeaderContentType.json,
            "Authorization": "Bearer " + localStorage.getItem('Access_Token')
        }
    }
}

API.shared = new API()