import React, { Component } from 'react';
import './App.css';
import { Route, BrowserRouter } from 'react-router-dom';

import Login from './containers/Login/Login';
import ChangePassword from './containers/ChangePassword/ChangePassword';
import ForgotPassword from './containers/ForgotPassword/ForgotPassword';
import SetPassword from './containers/SetPassword/SetPassword';
import Dashboard from './containers/Dashboard/Dashboard';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Route exact path="/" name="Login" component={Login} />
        <Route exact path="/login" name="Login" component={Login} />
        <Route exact path="/forgotpassword" name="ForgotPassword" component={ForgotPassword} />
        <Route exact path="/setpassword" name="SetPassword" component={SetPassword} />
        <Route exact path="/changepassword" name="ChangePassword" component={ChangePassword} />
        <Route exact path="/Dashboard" name="Dashboard" component={Dashboard} />
      </BrowserRouter>
    );
  }
}

export default (App);
