import { makeStyles } from '@material-ui/core/styles';

const buttonBaseStyle = makeStyles(theme => ({
  button: {
    borderRadius: 4,
    height: 35,
    fontFamily: 'Nunito, sans-serif',
    fontWeight: 600,
    fontSize: '16px',
    color: '#ffffff',
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main,
    textTransform: 'none'
  }
}));

export default buttonBaseStyle;
