import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
const membersStyle = theme => ({
    mainContainer: {
        borderRadius: '10px',
        boxShadow: '0 4px 16px 0 rgba(0,0,0,0.5)',
    },
    textField: {
        color: '#32373c',
        fontFamily: 'Lato-medium',
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1.57,
        alignSelf: 'flex-start',
        textAlign: 'left'
    },
    avatar: {
        backgroundColor: 'red',
    },
    chartFluid: {
        width: '100%',
        minWidth: 200,
        height: 250,
        margin: '0px 5px',
    },
    lables: {
        color: '#fff',
        fontWeight: 500,
        padding: "10px",
    },
    mainDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        color: '#000',
        backgroundColor: '#985647'
    },
    evenDiv: {
        backgroundColor: '#f9f9f9',
        // borderBottom: "1px solid #000",
        padding: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        '& label': {
            fontSize: '14px'
        }
    },
    oddDiv: {
        backgroundColor: '#f0f0f0',
        // borderBottom: "1px solid #000",
        padding: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        '& label': {
            fontSize: '14px'
        }
    },
});
const combinedStyles = combineStyles(commonStyle, membersStyle);
export default combinedStyles;
