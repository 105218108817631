import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
const loginStyle = theme => ({
    mainContainer: {
        borderRadius: '10px',
        boxShadow: '0 4px 16px 0 rgba(0,0,0,0.5)',
    },
    txtLogin: {
        marginBottom: '5%',
        fontFamily: 'Nunito, sans-serif',
        fontWeight: 700,
        fontSize: '22px',
        color: theme.palette.primary.main
    },
    img: {
        width: '100%',
        height: 470,
    },
    rememberMe: {
        fontFamily: 'Nunito, sans-serif',
        fontWeight: 400,
        fontSize: '14px',
        color: '#696974',
    },
    txtForgotPwd: {
        fontFamily: 'Nunito, sans-serif',
        fontWeight: 600,
        fontSize: '14px',
        color: theme.palette.primary.main,
        cursor: 'pointer',
    },
});
const combinedStyles = combineStyles(commonStyle, loginStyle);
export default combinedStyles;
