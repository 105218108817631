/* eslint eqeqeq: 0 */
import React, { Component } from 'react';
import pickupsStyle from './PickupsStyle';
import { withStyles } from '@material-ui/core/styles';
import { CssBaseline, Typography, Grid, CircularProgress, MenuItem } from '@material-ui/core';
import FilledTextField from '../../components/FilledTextField/FilledTextField';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import Scrollbars from 'react-custom-scrollbars';
import * as APIController from '../../api/APIController';

class Pickups extends Component {
    constructor(props) {
        super(props);
        this.state = {
            datasourcePickup: [],
            isEditPickup: false,
            pickupLoading: true,
            mediumBrowser: false,
            isLoading: false,
            datasourceStore: [],
            Remark: '',
            StoreID: ''
        };
        this.wsGetMyPickups()
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }

    wsGetMyPickups() {
        APIController.GetMyPickups().then((response) => {
            this.setState({ datasourcePickup: response.object.data, pickupLoading: false });
        });
    }

    wsGetUserStores() {
        APIController.GetUserStores().then((response) => {
            response.object.data.length == 1 && this.setState({ StoreID: response.object.data[0].StoreID })
            this.setState({ datasourceStore: response.object.data, storeLoading: false });
        });
    }

    handleChange = (e) => {
        this.setState({ [e.target.value]: e.target.value });
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit() {
        if (this.validateForm()) {
            APIController.AddEditPickup('0', localStorage.getItem("UserID"), this.state.StoreID, this.state.Remark)
                .then((response) => {
                    if (response.object.status == "1") {
                        ToastsStore.success(response.object.message)
                        this.setState({ datasourceStore: response.object.data, storeLoading: false });
                        this.props.onClose()
                    }
                    else {
                        ToastsStore.success(response.object.message)
                    }
                });
        }
    }

    validateForm() {
        var IsValid = 0;
        if (this.state.StoreID == '') {
            ToastsStore.error("Please select store");
        } else {
            IsValid = 1;
        }
        return IsValid;
    }

    renderPickupList() {
        const { datasourcePickup } = this.state;
        const { classes, handleTitle } = this.props;
        return (<div style={{ width: '500px', height: "400px", maxHeight: "400px", }}>
            {handleTitle("Pickups")}
            {!datasourcePickup.length ?
                <Grid container style={{ width: '100%', bottom: '0%', top: '0%', left: '0%', right: '0%' }} direction="row" justify='center' alignItems='center'>
                    <Typography style={{ fontSize: '18px', fontWeight: '600', fontFamily: 'Nunito, sans-serif', textAlign: 'center' }}> No Store Found </Typography>
                </Grid>
                : <Scrollbars style={{ maxHeight: 385, width: '100%', }}
                    autoHeightMax={800}
                    autoHide hideTracksWhenNotNeeded={true} >
                    {datasourcePickup.map((item, index) =>
                        <div style={{
                            width: '96%', fontSize: "13px", maxHeight: "235px", overflowY: 'auto', padding: '5px', margin: '2%', display: 'flex', justifyContent: 'space-between',
                            borderRadius: '5px', boxShadow: '0 0 20px rgb(0,0,0,0.2)',
                        }}>
                            <div style={{ display: 'grid', color: '#000' }}>
                                <label style={{ color: '#262628', fontSize: '14px', fontWeight: '700' }}>{item.StoreName}</label>
                                <label>{item.RequestedAt}</label>
                                <label>{item.RejectionRemarks == null && item.RejectionRemarks == "" ? item.AssignedTo : item.RejectionRemarks}</label>
                            </div>
                            <div style={{ padding: '5px 5px 0' }}>
                                <label className={
                                    item.StatusLabel == "Placed" ? classes.OrderStatusPlaced
                                        : item.StatusLabel == "Approved" ? classes.OrderStatusApproved
                                            : item.StatusLabel == "Assigned" ? classes.OrderStatusAssigned
                                                : item.StatusLabel == "Rejected" ? classes.OrderStatusRejected
                                                    : item.StatusLabel == "Pickedup" ? classes.OrderStatusAssigned
                                                        : item.StatusLabel == "Cancelled" && classes.OrderStatusRejected
                                }>{item.StatusLabel}</label>
                            </div>
                        </div>)}
                </Scrollbars>
            }
        </div>
        );
    }

    renderForm() {
        const { datasourceStore, isLoading, Remark, StoreID } = this.state;
        return (<>
            {this.props.handleTitle("Add Pickup")}
            <form noValidate style={{ width: '500px' }}>
                <Grid container style={{ marginBottom: '3%' }}>
                    <FilledTextField label={'Select Store'} rows={1} name="StoreID" value={StoreID} onChange={(e) => this.setState({ StoreID: e.target.value })} select style={{ marginTop: '2%' }}>
                        {datasourceStore.map(option => (
                            <MenuItem key={option.StoreID} value={option.StoreID}>
                                {option.StoreName}
                            </MenuItem>
                        ))}
                    </FilledTextField>

                    <FilledTextField label={'Enter Remark'} rows={1} name="Remark" value={Remark} onChange={(e) => this.setState({ Remark: e.target.value })} style={{ marginTop: '2%' }} />

                    <ButtonBase onClick={() => { this.handleSubmit() }} title={'Submit'} isLoading={isLoading} style={{ marginTop: '5%' }} />
                </Grid>
            </form >
        </>);
    }

    LoadingView() {
        return (
            <Grid container style={{ width: '100%', padding: "100px" }} direction={'row'} justify={'center'} alignItems='center'>
                <CircularProgress />
            </Grid>
        )
    }

    render() {
        const { pickupLoading, isEditPickup } = this.state;
        const { classes } = this.props;
        if (pickupLoading) { return this.LoadingView() }
        else {
            return (
                <React.Fragment>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px', }}>
                        {!isEditPickup && <label style={{ fontWeight: 600, color: '#000', cursor: 'pointer' }} onClick={() => { this.wsGetUserStores(); this.setState({ isEditPickup: true }) }}>Add New Pickups</label>}
                    </div>
                    <div style={{ width: '100%', overflowY: 'auto', marginBottom: '2px', }}>
                        {!isEditPickup ? this.renderPickupList() : this.renderForm()}
                    </div>
                    <CssBaseline />
                    <ToastsContainer store={ToastsStore} />
                </React.Fragment>
            );
        }
    }
}

export default withStyles(pickupsStyle)(Pickups);
