
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core'
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const styles = theme => ({
    text: {
        fontFamily: ['Lato', 'sans-serif'].join(','),
        fontWeight: 700,
        fontSize: 18,
    },
});
function Loader(props) {
    const { classes } = props;
    return (
        <Grid container justify='center' alignItems='center' style={{ width: '100%' }}>
            <div style={{ opacity: '0.8', borderRadius: '10px', textAlign: 'center', padding: '20px' }}>
                <CircularProgress size={50} />
                <Typography className={classes.text}> Loading...</Typography>
            </div>
        </Grid>
    )
}

export default withStyles(styles)(Loader);