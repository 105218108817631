import React, { Component } from 'react';
import * as APIController from '../../api/APIController';
import forgotpasswordStyle from './ForgotPasswordStyle';
import { withStyles } from '@material-ui/core/styles';
import { Images } from '../../common';
import { CssBaseline, Grid, Typography } from '@material-ui/core';
import FilledTextField from '../../components/FilledTextField/FilledTextField';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import * as AppConstants from '../../common/Constants';
import { ToastsContainer, ToastsStore } from 'react-toasts';

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            MobileNumber: '',
            loading: false,
        };
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.validateForm()) {
            this.wsForgotPassword();
        }
    }

    validateForm() {
        var IsValid = 0;
        if (this.state.MobileNumber.trim() === '') {
            ToastsStore.error(AppConstants.ValidationErrorMessages.NOMOBILENO);
        } else if (!this.state.MobileNumber.match(/^[0-9]+$/)) {
            ToastsStore.error(AppConstants.ValidationErrorMessages.MOBILENONOTANUMBER);
        } else if (this.state.MobileNumber.length < 10) {
            ToastsStore.error(AppConstants.ValidationErrorMessages.MOBILENOLENGTH);
        } else {
            IsValid = 1;
        }
        return IsValid;
    }

    wsForgotPassword() {
        this.setState({ loading: true });
        APIController.ForgotPassword(this.state.MobileNumber).then((response) => {
            if (response.error == null) {
                this.setState({ loading: false });
                ToastsStore.success(response.object.message);
                // this.props.history.push(AppConstants.SCREENS.LOGIN);
                this.setState({ MobileNumber: '' });
            }
            else {
                ToastsStore.error(response.error.message);
            }

        });
    }

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    renderForm() {
        return (
            <form noValidate onSubmit={this.handleSubmit.bind(this)}>
                <Grid container alignItems="center" justify={'center'}>
                    <FilledTextField
                        label={'Mobile Number'}
                        rows={1}
                        name="MobileNumber"
                        onChange={this.handleChange}
                        inputProps={{ maxLength: 10, }} />
                    <Grid container direction='row' justify={'space-between'} alignItems='center' style={{ width: '100%', margin: '8% 0' }}>
                        <ButtonBase type="submit" title={'Reset'} style={{ width: '40%' }} />
                        <ButtonBase type="submit" title={'Login'} onClick={() => this.props.history.push(AppConstants.SCREENS.LOGIN)} style={{ width: '40%' }} />
                    </Grid>
                </Grid>
            </form>
        );
    }

    render() {
        const { classes } = this.props;
        const { mediumBrowser } = this.state;
        return (
            <React.Fragment>
                <CssBaseline />
                <ToastsContainer store={ToastsStore} />
                <main className={classes.main}>
                    <Grid container justify="flex-end" alignItems='center' style={{ width: '98%', top: 0, bottom: 0, left: 0, right: 0 }} >
                        <Grid container className={classes.mainContainer} style={mediumBrowser ? { width: '100%', backgroundColor: "#fff" } : { width: '30%', backgroundColor: "#fff" }} >
                            <Grid container justify={'center'}>
                                <div style={{ width: '180px', height: '80px', }}>
                                    <img src={Images.logoImage} style={{ margin: '5% 0', aspectRatio: 'auto', maxHeight: '100%', maxWidth: '100%', }} alt="" />
                                </div>
                            </Grid>
                            <Grid container style={{ margin: "0 5%" }} >
                                <Typography className={classes.txtLogin}>Forgot Password</Typography>
                                {this.renderForm()}
                            </Grid>
                        </Grid>
                    </Grid>
                </main>
            </React.Fragment>
        );
    }
}
export default withStyles(forgotpasswordStyle)(ForgotPassword);