import React from 'react';
import { TextField } from '@material-ui/core';

import filledTextFieldStyle from './FilledTextFieldStyle';

const FilledTextField = props => {
    const classes = filledTextFieldStyle();
    return (
        <TextField
            // className={classes.textField}
            variant="standard"
            fullWidth
            InputProps={{
                className: classes.textField,
            }}
            InputLabelProps={{
                className: classes.label,
            }}
            {...props}>
            {props.children}
        </TextField>
    );
};

export default FilledTextField;
