import React from 'react';
import { AppBar, Toolbar, Typography, Grid, Menu, MenuItem, Avatar, IconButton, Badge, makeStyles } from '@material-ui/core';
import { NotificationsNoneOutlined, Business, Store, RateReview, BorderColorOutlined, ExitToAppOutlined, Refresh } from '@material-ui/icons';

import * as AppConstants from '../../common/Constants';
import * as APIController from '../../api/APIController';
import CommonModal from "../Modal/CommonModal";
import Notifications from "./Notifications";

import Stores from "../../containers/Store/Stores";
import Companies from "../../containers/Companies/Companies";
import Pickups from "../../containers/Pickups/Pickups";
import ChangePassword from "../../containers/ChangePassword/ChangePassword";
import TenantFormComponent from '../../containers/ChangeTenat/TenantFormComponent';
import { Images } from '../../common';

const useStyles = makeStyles(theme => ({
    toolBar: {
        height: '70px',
        shadows: "none",
        border: 0
    }, drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'space-between'
    }, appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),

        position: 'fixed'
    }, avatarStyle: {
        backgroundColor: '#f1f1f1',
        // color: theme.palette.primary.main
    }
}));

const Header = props => {
    React.useEffect(() => {
        wsGetNotifications()
    }, []);

    const classes = useStyles();
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const [openDialog, setopenDialog] = React.useState(false);
    const [DialogTitle, setDialogTitle] = React.useState('');
    const [isPickupsDialog, setisPickupsDialog] = React.useState(false);
    const isMenuOpen1 = Boolean(anchorEl1);
    const menuId = 'primary-search-account-menu';
    const [NotificationData, setNotificationData] = React.useState([]);
    const [PasswordModal, setPasswordModal] = React.useState(false);
    const [CompaniesModal, setCompaniesModal] = React.useState(false);
    const [NotificationModal, setNotificationModal] = React.useState(false);
    const [Open, setOpen] = React.useState(false);

    const handleProfileMenuOpen = event => { setAnchorEl1(event.currentTarget); };
    const handleMenuClose = () => { setAnchorEl1(null); };
    const handleTitle = (Title) => { setDialogTitle(Title) }

    const wsGetNotifications = () => {
        APIController.GetMyNotifications().then((response) => {
            setNotificationData(response.object.data)
        });
    }


    const handleCloseModal = () => {
        setOpen(false)
    }


    const renderProfileMenu = (
        <Menu style={{ top: '60px' }} justifyContent='center' alignItems='center' anchorEl={anchorEl1} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} id={menuId} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'center' }} open={isMenuOpen1} onClose={handleMenuClose}>
            <MenuItem justifyContent='center' alignItems='center' onClick={() => { setopenDialog(true); setisPickupsDialog(false) }} direction='row' style={{ marginTop: '5%', marginBottom: '5%' }}>
                <Store style={{ fontSize: '18px', fontWeight: 600, textAlign: 'center' }} />&nbsp;&nbsp;
                <Typography style={{ fontSize: '14px', fontWeight: 600, fontFamily: 'Nunito, sans-serif', textAlign: 'center' }}>Stores</Typography>
            </MenuItem>
            <MenuItem justifyContent='center' alignItems='center' onClick={() => { setopenDialog(true); setisPickupsDialog(true) }} direction='row' style={{ marginTop: '5%', marginBottom: '5%' }}>
                <RateReview style={{ fontSize: '18px', fontWeight: 600, textAlign: 'center' }} />&nbsp;&nbsp;
                <Typography style={{ fontSize: '14px', fontWeight: 600, fontFamily: 'Nunito, sans-serif', textAlign: 'center' }}>Pickups</Typography>
            </MenuItem>
            <MenuItem justifyContent='center' alignItems='center' onClick={() => { setPasswordModal(true); setCompaniesModal(false) }} direction='row' style={{ marginTop: '5%', marginBottom: '5%' }}>
                <BorderColorOutlined style={{ fontSize: '18px', fontWeight: 600, textAlign: 'center' }} />&nbsp;&nbsp;
                <Typography style={{ fontSize: '14px', fontWeight: 600, fontFamily: 'Nunito, sans-serif', textAlign: 'center' }}>Change Password</Typography>
            </MenuItem>
            <MenuItem justifyContent='center' alignItems='center' onClick={() => { localStorage.clear(); window.history.replaceState(null, null, "/"); props.history.push('/'); APIController.RegisterBrowserNotificationToken("").then(() => { }) }} direction='row' style={{ marginTop: '5%', marginBottom: '5%' }}>
                <ExitToAppOutlined style={{ fontSize: '18px', fontWeight: 600, textAlign: 'center' }} />&nbsp;&nbsp;
                <Typography style={{ fontSize: '14px', fontWeight: 600, fontFamily: 'Nunito, sans-serif', textAlign: 'center' }}>Logout</Typography>
            </MenuItem>
        </Menu>
    )

    return (
        <div>
            <AppBar position="fixed" style={{ backgroundColor: '#ffffff', color: '#000' }} className={classes.appBar} elevation={1}>
                <Toolbar className={classes.toolBar}>
                    <Grid container direction='row' justifyContent='space-between' alignItems='center' style={props.browser ? { width: '100%' } : { width: '100%' }} >
                        <Grid onClick={() => props.history.push(AppConstants.SCREENS.REQUIREMENTS)} container style={{ cursor: 'pointer', width: '25%' }} direction={'row'} alignItems='flex-end' >
                            <img src={Images.logoImage} style={{ hight: '60%', width: '40%', }} alt="" />
                        </Grid>
                        <Grid container direction='row' justifyContent='flex-end' alignItems='center' style={props.browser ? { width: '75%', } : { width: '25%', }}>
                            <Typography style={{ fontSize: '14px', fontWeight: 600, fontFamily: 'Nunito, sans-serif', textAlign: 'center' }} >{localStorage.getItem('TenantName')}</Typography>
                            <IconButton onClick={() => setOpen(true)} style={{ backgroundColor: 'transparent', color: '#000' }}>
                                <Refresh style={{ color: '#584e4e' }} />
                            </IconButton>
                            <IconButton onClick={() => { setPasswordModal(true); setCompaniesModal(true) }} style={{ backgroundColor: 'transparent' }}>
                                <Business style={{ color: '#584e4e' }} />
                            </IconButton>
                            <IconButton onClick={() => setNotificationModal(true)} aria-label="show 11 new notifications" style={{ backgroundColor: 'transparent' }}>
                                <Badge badgeContent={NotificationData.length} color="secondary">
                                    <NotificationsNoneOutlined style={{ color: '#584e4e' }} />
                                </Badge>
                            </IconButton>
                            <IconButton onClick={handleProfileMenuOpen} style={{ backgroundColor: 'transparent' }}>
                                <Avatar className={classes.avatarStyle} style={{ color: '#000' }} src={''}  >{(localStorage.getItem("UserFullName") || ' ').charAt(0)}</Avatar>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            {renderProfileMenu}

            {Open && <CommonModal
                Title={"Change Stockiest"}
                ActionText={'Submit'}
                IsDeletePopup={false}
                onClose={() => handleCloseModal()}
                maxWidth={"md"} >
                <TenantFormComponent />
            </CommonModal>}

            {openDialog && <CommonModal
                maxWidth={'md'}
                Title={DialogTitle}
                onClose={() => setopenDialog(false)} IsScroll >

                {!isPickupsDialog
                    ? <Stores handleTitle={handleTitle.bind(this)} />
                    : <Pickups handleTitle={handleTitle.bind(this)} onClose={() => setopenDialog(false)} />}
            </CommonModal>}

            {PasswordModal && <CommonModal
                maxWidth={'md'}
                Title={DialogTitle}
                onClose={() => setPasswordModal(false)} IsScroll >
                {CompaniesModal
                    ? <Companies handleTitle={handleTitle.bind(this)} />
                    : <ChangePassword handleTitle={handleTitle.bind(this)} />}

            </CommonModal>}
            {NotificationModal && <CommonModal
                maxWidth={'md'}
                Title={"Notifications"}
                onClose={() => setNotificationModal(false)} IsScroll >
                <Notifications NotificationData={NotificationData} />
            </CommonModal>}

            <main>
                <div className={classes.drawerHeader} />
            </main>
        </div>
    );
};

export default Header;
