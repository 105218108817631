import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
const requirementsStyle = theme => ({
    main: {
        backgroundColor: '#ffffff',
        paddingBottom: theme.spacing(2.7)
    },
    mainContainer: {
        borderRadius: '10px',
        boxShadow: '0 4px 16px 0 rgba(0,0,0,0.5)',
    },
    txtLogin: {
        marginTop: '2%',
        fontFamily: 'Nunito, sans-serif', fontWeight: 700, fontSize: '22px',
        marginLeft: '3%',
    },
    oddCartManiView: {
        backgroundColor: '#f0f0f0',
        width: '100%',
        marginBottom: '2px',
        padding: '5px 10px 5px 5px',
        borderRadius: '5px',
        border: '1px solid #808080',
        overflow: 'hidden'
    },
    evenCartManiView: {
        backgroundColor: '#f9f9f9',
        width: '100%',
        marginBottom: '2px',
        padding: '5px 10px 5px 5px',
        borderRadius: '5px',
        border: '1px solid #808080',
        overflow: 'hidden'
    },
    root: {
        // flexGrow: 1,
    },
    menuButton: {
        // marginRight: theme.spacing(2),
    },
    title: {
        // flexGrow: 1,
    },
    OrderStatusApproved: {
        backgroundColor: '#dff0d8',
        color: '#3c763d',
        fontWeight: '800',
        fontFamily: 'Nunito, sans-serif',
        fontSize: '14px',
        padding: "4px 18px",
        borderRadius: '20px',
    },
    OrderStatusAssigned: {
        backgroundColor: theme.palette.primary.main,
        color: '#e8eaf6',
        fontWeight: '800',
        fontFamily: 'Nunito, sans-serif',
        fontSize: '14px',
        padding: "4px 18px",
        borderRadius: '20px',
    },
    OrderStatusRejected: {
        backgroundColor: '#f2dede',
        color: '#a94442',
        fontWeight: '800',
        fontFamily: 'Nunito, sans-serif',
        fontSize: '14px',
        padding: "4px 18px",
        borderRadius: '20px',
    },
    OrderStatusPlaced: {
        backgroundColor: '#e0e0e0',
        color: '#212121',
        fontWeight: '800',
        fontFamily: 'Nunito, sans-serif',
        fontSize: "14px",
        padding: "3px 18px",
        borderRadius: "20px"
    },
    radio: {
        '&$checked': {
            color: theme.palette.primary.main
        }
    },
    checked: {},
    oddOrderManiView: {
        backgroundColor: '#f0f0f0',
        width: '100%',
        marginBottom: '2px',
        padding: '0 10px 0 5px',
        borderRadius: '5px',
        border: '1px solid #808080',
        overflow: 'hidden',
        cursor: 'pointer'
    },
    evenOrderManiView: {
        backgroundColor: '#f9f9f9',
        width: '100%',
        marginBottom: '2px',
        padding: '0 10px 0 5px',
        borderRadius: '5px',
        border: '1px solid #808080',
        overflow: 'hidden',
        cursor: 'pointer'
    }, priceReviewText: {
        color: theme.palette.primary.main,
        fontSize: '14px',
        fontWeight: '700',
        fontFamily: 'Nunito, sans-serif'
    }
});
const combinedStyles = combineStyles(commonStyle, requirementsStyle);
export default combinedStyles;
