import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
const forgotpasswordStyle = theme => ({
    mainContainer: {
        borderRadius: '10px',
        boxShadow: '0 4px 16px 0 rgba(0,0,0,0.5)',
    },
    img: {
        width: '100%',
        height: 470,
    },
    txtLogin: {
        marginBottom: window.innerWidth <= 920 ? '5%' : '7%',
        marginLeft: window.innerWidth <= 920 ? '' : '0',
        marginTop: '3%',
        fontFamily: 'Nunito, sans-serif',
        fontWeight: 700,
        fontSize: '22px',
        width: '100%'
    },
    titletext: {
        fontFamily: 'Nunito, sans-serif',
        fontWeight: 700,
        fontSize: '28px',
        lineHeight: 1.13,
        letterSpacing: 0,
        color: '#171725',
        // marginLeft:'5.5%'
    },
});
const combinedStyles = combineStyles(commonStyle, forgotpasswordStyle);
export default combinedStyles;
