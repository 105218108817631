import React from 'react'; import { AppBar, Toolbar, Typography, makeStyles } from '@material-ui/core';
import * as AppConstants from '../../common/Constants';

const useStyles = makeStyles(theme => ({
    appBar: {
        top: 'auto',
        bottom: 0,
        position: 'fixed',
        backgroundColor: theme.palette.primary.main,
    }, toolBar: {
        height: '20px',
        minHeight: '25px',
        shadows: "none",
        border: 0
    }
}));

const Footer = props => {
    const classes = useStyles();

    return (
        <div>
            <AppBar position="fixed" className={classes.appBar} elevation={5} style={{ backgroundColor: '#ffffff', color: '#000' }}>
                <Toolbar className={classes.toolBar}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', }}>
                        <div style={{ width: '30%', display: 'flex', }}>
                            <Typography style={{ color: '#584e4e', fontWeight: 400, fontSize: AppConstants.FONTSIZE.FS12, fontFamily: 'Nunito, sans-serif' }}>Contact us:</Typography>&nbsp;
                            <a href={"tel: +919898271413"} style={{ color: '#584e4e', fontWeight: 400, fontSize: AppConstants.FONTSIZE.FS12, fontFamily: 'Nunito, sans-serif', cursor: 'pointer', textDecorationLine: 'none' }}>+91 98982 71413</a></div>
                        <div style={{ width: '69%', display: 'flex', justifyContent: 'end' }}>
                            <Typography style={{ color: '#584e4e', fontWeight: 400, fontSize: AppConstants.FONTSIZE.FS12, fontFamily: 'Nunito, sans-serif', cursor: 'pointer' }}>Terms & Conditions</Typography>&nbsp;
                            <Typography style={{ color: '#584e4e', fontWeight: 400, fontSize: AppConstants.FONTSIZE.FS12, }}>|</Typography>&nbsp;
                            <Typography style={{ color: '#584e4e', fontWeight: 400, fontSize: AppConstants.FONTSIZE.FS12, fontFamily: 'Nunito, sans-serif', cursor: 'pointer' }}>Privacy Policy</Typography>&nbsp;
                            <Typography style={{ color: '#584e4e', fontWeight: 400, fontSize: AppConstants.FONTSIZE.FS12, }}>|</Typography>&nbsp;
                            <Typography style={{ color: '#584e4e', fontWeight: 400, fontSize: AppConstants.FONTSIZE.FS12, fontFamily: 'Nunito, sans-serif', cursor: 'pointer' }}>Return & Refund Policy</Typography>&nbsp;
                            <Typography style={{ color: '#584e4e', fontWeight: 400, fontSize: AppConstants.FONTSIZE.FS12, }}>|</Typography>&nbsp;
                            <Typography style={{ color: '#584e4e', fontWeight: 400, fontSize: AppConstants.FONTSIZE.FS12, fontFamily: 'Nunito, sans-serif', }}>© {new Date().getFullYear()} Kmisto. All Rights Reserved.</Typography>
                        </div>
                    </div>

                </Toolbar>
            </AppBar>
        </div>
    );
};

export default Footer;
