/* eslint eqeqeq: 0 */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper } from '@material-ui/core';

import tenantStyle from './TenantStyle';

class Tenant extends Component {

    render() {
        const { classes, TenantsData, changeMyTenant } = this.props;
        return (
            <Grid container alignItems='center' style={{ overflowY: 'auto', maxHeight: '450px' }} >
                {TenantsData.map((value, index) => (
                    <Paper className={classes.cardStyle} elevation={10}
                        onClick={() => { changeMyTenant(value.ID) }}>
                        <Grid key={index} item justify="center" alignItems="center" style={{ elevation: 10 }}>
                            <img alt="img" src={value.LogoURL} style={{ height: '100px', width: '100px' }} />
                            <Typography display='block' className={classes.CartTextStyle}> {value.Name}</Typography>
                        </Grid>
                    </Paper>
                ))}
            </Grid>
        );
    }
}
export default withStyles(tenantStyle)(Tenant);