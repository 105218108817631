
export const ValidationErrorMessages = {
  NOMOBILENO: 'Please enter Mobile Number',
  NOPASSWORD: 'Please enter Password',
  NONEWPASSWORD: 'Please enter New Password',
  NOCONFIRMPASSWORD: 'Please enter Confirm Password',
  PASSWORDSHOULDMATCH: 'New and Confirm Password should match',
  NOOTP: 'Please enter OTP',
  MOBILENOLENGTH: 'Mobile Number should be 10 Digits',
  MOBILENONOTANUMBER: 'Mobile Number should be Digits',
  OTPLENGTH: 'OTP should be 4 Digits'
}

export const SCREENS = {
  LOGIN: 'Login',
  FORGOTPASSWORD: 'ForgotPassword',
  DASHBOARD: 'Dashboard'
}

export const FONTSIZE = {
  FS5: '5px',
  FS6: '6px',
  FS7: '7px',
  FS8: '8px',
  FS9: '9px',
  FS10: '10px',
  FS11: '11px',
  FS12: '12px',
  FS13: '13px',
  FS14: '14px',
  FS15: '15px',
  FS16: '16px',
  FS17: '17px',
  FS18: '18px',
  FS19: '19px',
  FS20: '20px',
  FS22: '22px',
  FS25: '25px',
  FS30: '30px',
  FS35: '35px',
  FS38: '38px',
  FS40: '40px',
}