import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
const changepasswordStyle = theme => ({
    mainContainer: {
        borderRadius: '10px',
        boxShadow: '0 4px 16px 0 rgba(0,0,0,0.5)',
    },
    txtLogin: {
        // marginBottom: '5%',
        marginTop:'3%',
        fontFamily: 'Nunito, sans-serif', fontWeight: 700,
        fontSize: '22px',
        marginLeft: window.innerWidth<=920?'':'7%',
    },
    innerContainer: {
        width: '60%',
        // color: theme.palette.primary.secondary,
        backgroundColor: '#FFFFFF',
        borderRadius: '20px',
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.04)',
    },
    innerContainerRight: {
        // backgroundColor: '#0062ff',
        width: '50%',
    },
    innerContainerLeft: {
        // backgroundColor: '#ffe6e6',
        width: '50%',
    },
    img: {
        width: '100%',
        height: 470,
    },
    textField: {
        color: '#32373c',
        fontFamily: 'Lato-medium',
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1.57,
        alignSelf: 'flex-start',
        textAlign: 'left'
    },
    titletext: {
        fontFamily: 'Nunito, sans-serif', 
        fontWeight: 700,
        fontSize: '28px',
        lineHeight: 1.13,
        letterSpacing: 0,
        color: '#171725',
        // marginLeft:'5.5%'
      },
});
const combinedStyles = combineStyles(commonStyle, changepasswordStyle);
export default combinedStyles;
