/* eslint eqeqeq: 0 */
import React, { Component } from 'react';
import CompaniesStyle from './CompaniesStyle';
import { CssBaseline, Typography, Grid, CircularProgress } from '@material-ui/core';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import * as APIController from '../../api/APIController';
import { withStyles } from '@material-ui/core/styles';
import Scrollbars from 'react-custom-scrollbars';
import FilledTextField from '../../components/FilledTextField/FilledTextField';

class Companies extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyName: '',
            adddress1: '',
            address2: '',
            postCode: '',
            email: '',
            mobileNo: '',
            CityID: '',
            IsActive: '',
            IsDefault: '',
            datasourceCompany: [],
            isLoading: false,
            mediumBrowser: false,
            companyLoading: true,
        };
        this.wsGetCompanyForDropdown();
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    wsGetCompanyForDropdown() {
        APIController.GetCompanyForDropdown().then((response) => {
            this.setState({ datasourceCompany: response.object.data, companyLoading: false });
            this.arrayholder = response.object.data;
        });
    }

    validateForm() {
        var IsValid = 0;
        if (this.state.companyName.trim() === '') {
            ToastsStore.error("Please enter Store Name");
        } else {
            IsValid = 1;
        }
        return IsValid;
    }

    handleSubmit() {
        if (this.validateForm()) {
        }
    }

    handleChange = (e) => {
        this.setState({ [e.target.value]: e.target.value });
        this.setState({ [e.target.name]: e.target.value });
    }

    SearchFilterFunction(text) {
        if (text != '') {
            const newData1 = this.arrayholder.filter(function (item) {
                return item.Name.toLowerCase().indexOf(text.toLowerCase()) != -1
            });
            this.setState({ datasourceCompany: newData1, companyName: text });
        }
        else {
            this.setState({ datasourceCompany: this.arrayholder, companyName: text });
        }
    }

    renderCompanyList() {
        const { datasourceCompany, companyName } = this.state;
        const { classes, handleTitle } = this.props;
        return (<div style={{ width: '500px', height: "450px", maxHeight: "450px", }}>
            {handleTitle("Companies")}
            <FilledTextField label={'Search Company'} rows={1} name="SearchCompany" value={companyName} maxLength={50}
                onChange={(e) => this.SearchFilterFunction(e.target.value)}
                style={{ width: '100%', marginBottom: '10px' }} />
            {!datasourceCompany.length ?
                <Grid container style={{ width: '100%', bottom: '0%', top: '0%', left: '0%', right: '0%' }} direction="row" justify='center' alignItems='center'>
                    <Typography style={{ fontSize: '18px', fontWeight: '600', fontFamily: 'Nunito, sans-serif', textAlign: 'center' }}> No Company Found </Typography>
                </Grid>
                : <Scrollbars style={{ maxHeight: 375, width: '100%', }}
                    autoHeightMax={800}
                    autoHide hideTracksWhenNotNeeded={true} >{datasourceCompany.map((item, index) =>
                        <div style={{ border: '1px solid #000', borderRadius: '5px', width: '100%', fontSize: "13px", maxHeight: "235px", overflowY: 'auto', marginBottom: '2px', }}>
                            <div className={!(index % 2) ? classes.oddDiv : classes.evenDiv} >
                                <label style={{ color: '#262628', fontSize: '14px', fontWeight: '700' }}>{item.Name}</label>
                            </div>
                        </div>)}
                </Scrollbars>}
        </div>);
    }

    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 });
    }
    LoadingView() {
        return (
            <Grid container style={{ width: '100%', padding: "100px" }} direction={'row'} justify={'center'} alignItems='center'>
                <CircularProgress />
            </Grid>
        )
    }

    render() {
        const { companyLoading } = this.state;
        if (companyLoading) { return this.LoadingView() }
        else {
            return (
                <React.Fragment>
                    <div style={{ width: '100%' }}>
                        {this.renderCompanyList()}
                    </div>
                    <CssBaseline />
                    <ToastsContainer store={ToastsStore} />
                </React.Fragment>
            );
        }
    }
}
export default withStyles(CompaniesStyle)(Companies);