import React from 'react';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import buttonBaseStyle from './ButtonBaseStyle';

const ButtonBase = props => {
  const classes = buttonBaseStyle();
  const { title, customClass, isLoading } = props;
  return (
    <Button
      fullWidth
      variant="contained"
      className={`${classes.button} ${customClass}`}
      disabled={isLoading}
      {...props}
    >
      {isLoading ? <CircularProgress size={20} /> : title}
    </Button>
  );
};

export default ButtonBase;
