import combineStyles from '../../common/combineStyles';
import commonStyle from '../../common/commonStyles';
const tenantStyle = theme => ({
    mainContainer: {
        borderRadius: '10px',
        boxShadow: '0 4px 16px 0 rgba(0,0,0,0.5)',
    },
    img: {
        width: '100%',
        height: 470,
    },
    txtLogin: {
        marginBottom: '5%',
        fontFamily: 'Nunito, sans-serif', fontWeight: 700,
        fontSize: '22px',
        marginLeft: window.innerWidth <= 920 ? '' : '7%',
    },
    innerContainer: {
        width: '60%',
        // backgroundColor: '#0062ff',
        borderRadius: '20px',
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.04)',
    },
    innerContainerRight: {
        // backgroundColor: '#0062ff',
        width: '50%',
    },
    innerContainerLeft: {
        // backgroundColor: '#ffe6e6',
        width: '50%',
    },
    cardStyle: {
        backgroundColor: '#0277bd !important',
        color: '#fff',
        opacity: 0.8,
        cursor: "pointer",
        padding: '20px',
        textAlign: 'center',
        width: '160px',
        height: '150px',
        margin: '8px',
        '&:hover': {
            color: '#fff',
            opacity: 1,
            boxShadow: theme.shadows[10],
        },
    },
});
const combinedStyles = combineStyles(commonStyle, tenantStyle);
export default combinedStyles;
