/* eslint eqeqeq: 0 */
import React, { Component } from 'react';
import otpStyle from './OTPStyle';
import * as APIController from '../../api/APIController';
import { withStyles } from '@material-ui/core/styles';
import { CssBaseline, Typography, Grid, CircularProgress } from '@material-ui/core';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import * as AppConstants from '../../common/Constants';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import OtpInput from 'react-otp-input';

class OTP extends Component {
    constructor(props) {
        super(props);
        this.state = {
            OTP: '',
            loading: false,
            mediumBrowser: false,
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }

    handleChange = (otp) => {
        if (otp.length == 4) {
            this.wsVerifyOTP(otp)
        }
        this.setState({ OTP: otp })
    }

    wsResendOTP() {
        this.setState({ loading: false });
        APIController.ResendOTP().then((response) => {
            this.setState({ loading: false });
            ToastsStore.error(response.object.message);
        });
    }

    wsVerifyOTP(otp) {
        if (otp === '') {
            ToastsStore.error(AppConstants.ValidationErrorMessages.NOOTP);
        } else if (otp.length < 4) {
            ToastsStore.error(AppConstants.ValidationErrorMessages.OTPLENGTH);
        } else {
            this.setState({ loading: true });
            APIController.VerifyOTP(otp).then((response) => {
                this.setState({ loading: false });
                if (response.error == null && response.object.status == 1) {
                    if (localStorage.getItem("TenantID") != "" && localStorage.getItem("TenantID") != null && localStorage.getItem("TenantID") != undefined) {
                        window.location.replace(AppConstants.SCREENS.DASHBOARD);
                        this.props.onClose(true)
                    } else {
                        this.props.onClose(true)
                        this.props.openTenantModal()
                    }
                } else { ToastsStore.error(response.error.message); }
            });
        }
    }

    renderForm() {
        const { OTP, mediumBrowser } = this.state;
        const { classes } = this.props;
        return (
            <div style={{ width: '400px' }}>
                <Grid container style={window.innerWidth <= 420 ? { width: '80%', marginTop: '5%' } : { width: '99%', }} alignItems="center" justify={mediumBrowser ? 'center' : 'flex-start'}>
                    <Typography className={classes.subtitle}>{"Please verify OTP sent to " + this.props.MobileNumber}</Typography>
                </Grid>
                <OtpInput
                    value={OTP}
                    onChange={this.handleChange}
                    inputStyle={{ width: '60%', height: '85%', margin: '10%', borderRadius: '10px', border: '1px solid #424242', fontWeight: '700', fontSize: '26px', }}
                    numInputs={4}
                    isInputNum={true}
                    shouldAutoFocus={true}
                />
                <ButtonBase type='submit' onClick={() => this.wsVerifyOTP(OTP)} title={'Verify OTP'} style={{ marginTop: '5%' }} />
                <label className={classes.linkText} style={{ marginTop: '5%', display: 'flex', justifyContent: 'center' }} onClick={() => this.wsResendOTP()}>{'Resend OTP'}</label>
            </div>
        );
    }

    render() {
        const { loading } = this.state;
        if (loading) {
            return (
                <Grid container style={{ width: '100%', padding: "100px" }} direction={'row'} justify={'center'} alignItems='center'>
                    <CircularProgress />
                </Grid>)
        } else {
            return (
                <React.Fragment>
                    <div style={{ width: '100%', overflowY: 'auto', marginBottom: '2px', }}>
                        {this.renderForm()}
                    </div>
                    <CssBaseline />
                    <ToastsContainer store={ToastsStore} />
                </React.Fragment>
            );
        }
    }
}
export default withStyles(otpStyle)(OTP);