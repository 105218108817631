/* eslint eqeqeq: 0 */
import React, { Component } from 'react';
import { Typography, Grid, withStyles, CircularProgress, IconButton } from '@material-ui/core';
import { ToastsContainer, ToastsStore } from 'react-toasts';

import Scrollbars from 'react-custom-scrollbars';
import ArrowBack from '@material-ui/icons/ArrowBack';

import dashboardStyle from './DashboardStyle';
import * as APIController from '../../api/APIController';
import FilledTextField from '../../components/FilledTextField/FilledTextField';
import ButtonBase from '../../components/ButtonBase/ButtonBase';

class ReviewOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            datasourceMedicine: [],
            isLoading: true,
            datasourceStore: [],
            CartTotal: 0,
            DiscountPrice: 0,
            GrossTotal: 0,
            instructionsText: ''
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        this.setState({ datasourceMedicine: this.props.MedicineData.Cart, datasourceStore: this.props.MedicineData.Store, isLoading: false })
        this.CalculateCartTotal(this.props.MedicineData.Cart)
    }

    wsPlaceOrder(StoreID, DeviceTypeID, DeliveryInstructions) {
        APIController.PlaceOrder(StoreID, DeviceTypeID, DeliveryInstructions).then((response) => {
            if (response.object.status == "1") {
                ToastsStore.success(response.object.message)
                this.props.isReviewOrder()
            } else {
                ToastsStore.success(response.object.message)
            }
        });
    }

    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }

    handleChange = (e) => {
        this.setState({ [e.target.value]: e.target.value });
        this.setState({ [e.target.name]: e.target.value });
    }

    LoadingView() {
        return (
            <Grid container style={{ width: '100%' }} direction={'row'} justify={'center'} alignItems='center'>
                <CircularProgress />
            </Grid>
        )
    }

    CalculateCartTotal(Data) {
        var SubTotal = 0;
        var Discount = 0;
        Data.map(function (item) {
            SubTotal = SubTotal + (item.Quantity * item.MRP);
            Discount = Discount + (item.Quantity * (item.MRP - item.SalePrice));
        });
        this.setState({
            CartTotal: SubTotal.toFixed(2),
            DiscountPrice: (Discount).toFixed(2),
            GrossTotal: Math.round((SubTotal - Discount).toFixed(2)),
        });
    }


    render() {
        const { datasourceMedicine, datasourceStore, isLoading, instructionsText } = this.state;
        const { classes, searchLoading, isReviewOrder } = this.props;
        return (<>
            <ToastsContainer store={ToastsStore} />
            <IconButton aria-label="delete" onClick={() => isReviewOrder()}>
                <ArrowBack />
            </IconButton>
            <Typography className={classes.txtLogin}>Review Order</Typography>
            {isLoading
                ? this.LoadingView()
                : <Grid container style={{ margin: '0 2% 2%', height: 490 }}>
                    <Scrollbars style={{ maxHeight: 265, width: '100%', }}
                        autoHeightMax={800}
                        autoHide hideTracksWhenNotNeeded={true} >
                        {searchLoading ? this.LoadingView() :
                            !datasourceMedicine.length ?
                                <Grid container style={{ width: '100%', bottom: '0%', top: '0%', left: '0%', right: '0%', position: 'absolute' }} direction="row" justify='center' alignItems='center'>
                                    <Typography style={{ fontSize: '18px', fontWeight: '600', fontFamily: 'Nunito, sans-serif', textAlign: 'center' }}> No Medicine Found </Typography>
                                </Grid>
                                : <Grid item>
                                    {datasourceMedicine != null && datasourceMedicine.map((item, index) =>
                                        <Grid container direction="row" justify="space-between" alignItems="center" wrap="nowrap"
                                            className={classes.cartManiView} style={item.AvailableQuantity < item.Quantity ? { backgroundColor: '#ffe0e0' } : {}} >
                                            <div style={{ width: '65%' }}>
                                                <Grid container direction="row" alignItems="center" >
                                                    <Typography noWrap style={{ color: '#262628', fontSize: '14px', fontWeight: '700', fontFamily: 'Nunito, sans-serif', paddingLeft: '5px' }}> {item.Name} </Typography>
                                                </Grid>
                                                <Grid container direction="row" >
                                                    <Typography style={{ color: '#9B9B9B', fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito, sans-serif', width: '40%', paddingLeft: '5px' }}>Pack of:&nbsp;{item.PackSize}</Typography>
                                                    <Typography style={{ color: '#9B9B9B', fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito, sans-serif', width: '40%' }}>Quantity:&nbsp;{item.Quantity}</Typography>
                                                </Grid>

                                            </div>

                                            <div style={{ width: '18%', textAlign: 'end' }}>
                                                <Typography style={{ color: '#39A749', fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito, sans-serif' }}>Price:&nbsp;₹ {item.Quantity == "" || item.Quantity == "0" ? item.SalePrice : (item.Quantity * item.SalePrice).toFixed(2)}</Typography>
                                                <Typography style={{ color: 'red', fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito, sans-serif', textDecorationLine: 'line-through', }}>₹ {item.Quantity == "" || item.Quantity == "0" ? item.MRP.toFixed(2) : (item.Quantity * item.MRP).toFixed(2)}</Typography>
                                            </div>
                                        </Grid>
                                    )}
                                </Grid>
                        }
                    </Scrollbars>
                    <FilledTextField label={'Enter Delivery Instructions'} rows={1} name="Search Medicine" value={instructionsText}
                        onChange={(e) => this.setState({ instructionsText: e.target.value })} style={{ marginBottom: '1%' }} />
                    <Grid container direction="row" style={{ backgroundColor: '#e5e3f0', width: '49%', padding: '2px 5px', borderRadius: '5px', border: '1px solid #808080', overflow: 'hidden', marginRight: '1%', marginBottom: '1%' }}>
                        <Grid direction="column" style={{ width: '73%' }}>
                            <Typography style={{ fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito, sans-serif' }}>PTR Total</Typography>
                            <Typography className={classes.priceReviewText}>Gross Total</Typography>
                            <Typography className={classes.priceReviewText}>GST & Scheme Applicable</Typography>
                        </Grid>
                        <Grid container direction="column" justifyContent="flex-start" alignItems='flex-end' style={{ width: '25%' }}>
                            <Typography style={{ fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito, sans-serif' }}>₹ {this.state.GrossTotal}</Typography>
                            <Typography className={classes.priceReviewText}>₹ {this.state.GrossTotal}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="column" style={{ backgroundColor: '#e5e3f0', width: '49%', padding: '2px 5px', borderRadius: '5px', border: '1px solid #808080', overflow: 'hidden', marginLeft: '1%', marginBottom: '1%' }}>
                        <Typography className={classes.priceReviewText}>{datasourceStore[0].StoreName}</Typography>
                        <Typography style={{ fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito, sans-serif' }}>{datasourceStore[0].Address1}</Typography>
                        <Typography style={{ fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito, sans-serif' }}>{datasourceStore[0].Address2}</Typography>
                        <Typography style={{ fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito, sans-serif' }}>{datasourceStore[0].CityName}</Typography>
                        <Typography style={{ fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito, sans-serif' }}>{datasourceStore[0].Postcode}</Typography>
                        <Typography style={{ fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito, sans-serif' }}>{datasourceStore[0].MobileNo}</Typography>
                    </Grid>
                    <Grid container style={{ width: '100%', }} alignItems="flex-end">
                        <ButtonBase onClick={() => { this.wsPlaceOrder(localStorage.getItem("StoreID"), "2", this.state.instructionsText) }} title={'Place Order'} />
                    </Grid>
                </Grid>}
        </>
        );
    }

} export default withStyles(dashboardStyle)(ReviewOrder);