import React, { Component } from 'react';
import * as APIController from '../../api/APIController';
import changepasswordStyle from './ChangePasswordStyle';
import { withStyles } from '@material-ui/core/styles';
import { CssBaseline, Grid, InputAdornment, IconButton } from '@material-ui/core';
import FilledTextField from '../../components/FilledTextField/FilledTextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import * as AppConstants from '../../common/Constants';
import { ToastsContainer, ToastsStore } from 'react-toasts';

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            OldPassword: '',
            NewPassword: '',
            ConfirmPassword: '',
            showtxtPassword: false,
            showtxtNewPassword: false,
            showtxtNewConfirmPassword: false,
            loading: false,
        };
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.validateForm()) {
            this.wsChangePassword();
        }
    }

    validateForm() {
        var IsValid = 0;
        if (this.state.OldPassword.trim() === '') {
            ToastsStore.error(AppConstants.ValidationErrorMessages.NOPASSWORD);
        } else if (this.state.NewPassword.trim() === '') {
            ToastsStore.error(AppConstants.ValidationErrorMessages.NONEWPASSWORD);
        } else if (this.state.ConfirmPassword.trim() === '') {
            ToastsStore.error(AppConstants.ValidationErrorMessages.NOCONFIRMPASSWORD);
        } else if (this.state.ConfirmPassword !== this.state.NewPassword) {
            ToastsStore.error(AppConstants.ValidationErrorMessages.PASSWORDSHOULDMATCH);
        } else {
            IsValid = 1;
        }
        return IsValid;
    }

    handleClickshowtxtPassword = () => {
        this.setState({
            showtxtPassword: !this.state.showtxtPassword,
        });
    };
    handleClickshowtxtNewPassword = () => {
        this.setState({
            showtxtNewPassword: !this.state.showtxtNewPassword,
        });
    };
    handleClickshowtxtNewConfirmPassword = () => {
        this.setState({
            showtxtNewConfirmPassword: !this.state.showtxtNewConfirmPassword
        });
    };

    wsChangePassword() {
        this.setState({ loading: true });
        APIController.ChangePassword(this.state.OldPassword, this.state.NewPassword).then((response) => {
            this.setState({ loading: false });
            if (response.error != null) {
                ToastsStore.error(response.error.message);
            } else {
                ToastsStore.success(response.object.data[0].message);
            }
        });
    }

    renderForm() {
        const { showtxtPassword, showtxtNewPassword, showtxtNewConfirmPassword, loading } = this.state;
        const { classes } = this.props;
        return (
            <form noValidate onSubmit={this.handleSubmit.bind(this)} style={{ width: '500px' }}>
                <Grid container style={{ width: '100%', height: '7.91%' }} alignItems="center">
                    <FilledTextField type={showtxtPassword ? 'text' : 'password'} label={'Enter Old Password'} name="OldPassword" onChange={this.handleChange} rows={1}
                        InputProps={{
                            className: classes.textField,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton aria-label="toggle password visibility" onClick={this.handleClickshowtxtPassword}>
                                        {showtxtPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid container style={{ width: '100%', height: '7.91%', marginTop: '5%' }} alignItems="center">
                    <FilledTextField type={showtxtNewPassword ? 'text' : 'password'} label={'Enter New Password'} name="NewPassword" onChange={this.handleChange} rows={1}
                        InputProps={{
                            className: classes.textField,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={this.handleClickshowtxtNewPassword}
                                    >
                                        {showtxtNewPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }} />
                </Grid>
                <Grid container style={{ width: '100%', height: '7.91%', marginTop: '5%' }} alignItems="center">
                    <FilledTextField type={showtxtNewConfirmPassword ? 'text' : 'password'} label={'Enter Confirm Password'} name="ConfirmPassword" onChange={this.handleChange} rows={1}
                        InputProps={{
                            className: classes.textField,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton aria-label="toggle password visibility" onClick={this.handleClickshowtxtNewConfirmPassword}>
                                        {showtxtNewConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }} />
                </Grid>
                <Grid container style={{ width: '100%', height: '7.91%', marginTop: '5%' }} >
                    <ButtonBase type="submit" title={'Update Password'} isLoading={loading} />
                </Grid>
            </form >
        );
    }

    render() {
        const { handleTitle } = this.props;
        return (
            <React.Fragment>
                <div style={{ width: '100%', overflowY: 'auto', marginBottom: '2px', }}>
                    {handleTitle("Change Password")}
                    {this.renderForm()}
                </div>
                <CssBaseline />
                <ToastsContainer store={ToastsStore} />
            </React.Fragment>
        );
    }
}

export default withStyles(changepasswordStyle)(ChangePassword);
