/* eslint eqeqeq: 0 */
import React, { Component } from 'react';
import * as APIController from '../../api/APIController';
import loginStyle from './LoginStyle';
import { Images } from '../../common';
import { withStyles, CssBaseline, Typography, Grid, InputAdornment, IconButton, } from '@material-ui/core';
import FilledTextField from '../../components/FilledTextField/FilledTextField';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import * as AppConstants from '../../common/Constants';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import authService from "../../services/auth-service";
import jwt_decode from "jwt-decode";

import CommonModal from "../../components/Modal/CommonModal";
import OTP from "../OTP/OTP";
import Tenant from "../Tenant/Tenant";


class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            MobileNumber: '',
            Password: '',
            showPassword: false,
            isLoading: false,
            mediumBrowser: false,
            openModal: false,
            otpModal: false,
            tenantModal: false,
        };
    }

    componentDidMount() {
        if (authService.checkLogin()) {
            this.wsVerifyToken();
        }
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.validateForm()) {
            this.wsLogin();
        }
    }

    validateForm() {
        var IsValid = 0;
        if (this.state.MobileNumber === '') {
            ToastsStore.error(AppConstants.ValidationErrorMessages.NOMOBILENO);
        } else if (!this.state.MobileNumber.match(/^[0-9]+$/)) {
            ToastsStore.error(AppConstants.ValidationErrorMessages.MOBILENONOTANUMBER);
        } else if (this.state.MobileNumber.length < 10) {
            ToastsStore.error(AppConstants.ValidationErrorMessages.MOBILENOLENGTH);
        } else if (this.state.Password === '') {
            ToastsStore.error(AppConstants.ValidationErrorMessages.NOPASSWORD);
        } else {
            IsValid = 1;
        }
        return IsValid;
    }

    wsVerifyToken() {
        APIController.VerifyToken().then((response) => {
            if (response.object.status == "1") {
                if (localStorage.getItem("TenantID") != "" && localStorage.getItem("TenantID") != null && localStorage.getItem("TenantID") != undefined) {
                    window.location.replace(AppConstants.SCREENS.DASHBOARD)
                } else {
                    this.wsGetMyTenants()
                }
            } else {
                ToastsStore.error(response.object.message)
            }
        }).catch((err) => { })
    }

    wsLogin() {
        this.setState({ isLoading: true });
        APIController.GetAccessToken(this.state.MobileNumber, this.state.Password)
            .then((response) => {
                this.setState({ isLoading: false })
                if (response.error != null) {
                    this.setState({ MobileNumber: '', Password: '' })
                    ToastsStore.error(response.error.code.error_description);
                } else {
                    this.wsRegisterBrowserNotificationToken(response)
                }
            });
    }

    wsRegisterBrowserNotificationToken(response) {
        this.setState({ isLoading: true })
        APIController.RegisterBrowserNotificationToken(localStorage.getItem('FirebaseToken')).then((res) => {
            if (res.object.status == "1") {

                this.handleStorageData(response)

                var decoded = jwt_decode(response.object.jwt);
                console.log('decoded', decoded);
                localStorage.setItem("MainColor", "#152b60")
                localStorage.setItem("MainImage", Images.logoImage1)
                if (decoded.VerifyOTP == '1') {
                    this.setState({ openModal: true, otpModal: true })
                } else if (decoded.TenantID == "") {
                    this.wsGetMyTenants()
                } else {
                    authService.setAuthData(response);
                    window.location.replace(AppConstants.SCREENS.DASHBOARD)
                }
            } else {
                ToastsStore.error(response.object.message);
            }
            this.setState({ isLoading: false })
        });
    }

    wsGetMyTenants = () => {
        APIController.GetMyTenants().then((response) => {
            if (response.error == null && response.object.data.length) {
                if (response.object.data.length == 1) {
                    this.wsChangeMyTenant(response.object.data[0].ID)
                } else {
                    this.setState({ TenantsData: response.object.data, openModal: true, otpModal: false, tenantModal: true })
                }
            }
        }).catch((err) => { })
    }

    wsChangeMyTenant = (TenantID) => {
        APIController.ChangeMyTenant(TenantID).then((response) => {
            this.handleStorageData(response)
            authService.setAuthData(response);
            window.location.replace(AppConstants.SCREENS.DASHBOARD)
            this.props.onClose(true)
        }).catch((err) => { })
    }

    handleStorageData(response) {
        var decoded = jwt_decode(response.object.jwt);

        localStorage.setItem('StoreID', decoded.StoreID)
        localStorage.setItem('UserFullName', decoded.FullName)
        localStorage.setItem('UserID', decoded.ID)
        localStorage.setItem('TenantID', decoded.TenantID);
        localStorage.setItem('TenantName', decoded.TN);
    }

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    renderForm() {
        const { showPassword, isLoading } = this.state;
        const { classes } = this.props;
        return (
            <form noValidate onSubmit={this.handleSubmit.bind(this)}>
                <Grid container alignItems="center" justifyContent={'center'}>
                    <FilledTextField
                        label={'Mobile Number'}
                        inputProps={{ maxLength: 10 }}
                        rows={1}
                        name="MobileNumber"
                        value={this.state.MobileNumber}
                        onChange={this.handleChange}
                    />
                    <FilledTextField style={{ marginTop: '5%', }} value={this.state.Password} type={showPassword ? 'text' : 'password'} label={'Password'} name="Password" onChange={this.handleChange} rows={1}
                        InputProps={{
                            className: classes.textField,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={this.handleClickShowPassword} >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }} />

                    <Grid container justifyContent='flex-end' style={{ marginTop: '5%' }}>
                        <Typography onClick={() => this.props.history.push(AppConstants.SCREENS.FORGOTPASSWORD)} className={classes.txtForgotPwd}>{'Forgot Password'}</Typography>
                    </Grid>
                    <Grid container style={{ margin: '5%' }} >
                        <ButtonBase type="submit" title={'Login'} isLoading={isLoading} />
                    </Grid>
                </Grid>
            </form>
        );
    }

    render() {
        const { classes } = this.props;
        const { mediumBrowser, otpModal, tenantModal } = this.state;
        return (
            <React.Fragment >
                <CssBaseline />
                <ToastsContainer store={ToastsStore} />

                <main className={classes.main} id='login-bg'>
                    <Grid container justify="flex-end" alignItems='center' style={{ width: '98%', top: 0, bottom: 0, left: 0, right: 0 }} >
                        <Grid container className={classes.mainContainer} style={mediumBrowser ? { width: '100%', backgroundColor: "#fff" } : { width: '30%', backgroundColor: "#fff" }} >
                            <Grid container style={{ width: '100%', height: '140px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ width: '180px', height: '80px', }}>
                                    <img src={Images.logoImage} style={{ margin: '5% 0', aspectRatio: 'auto', maxHeight: '100%', maxWidth: '100%', }} alt="" />
                                </div>
                            </Grid>
                            <Grid container style={{ margin: "0 5%" }} >
                                <Typography className={classes.txtLogin}>Login</Typography>
                                {this.renderForm()}
                            </Grid>
                        </Grid>
                    </Grid>
                </main>

                {this.state.openModal &&
                    <CommonModal
                        maxWidth={'md'}
                        Title={otpModal ? "Verify Account" : "Choose Your Tenant"}
                        OrderNO={" "}
                        onClose={() => otpModal && this.setState({ openModal: false, otpModal: false })} IsScroll >

                        {otpModal &&
                            <OTP
                                MobileNumber={this.state.MobileNumber}
                                onClose={() => { this.setState({ openModal: false, otpModal: false }) }}
                                openTenantModal={this.wsGetMyTenants.bind(this)} />}
                        {tenantModal &&
                            <Tenant
                                onClose={() => { this.setState({ openModal: false, tenantModal: false }) }}
                                TenantsData={this.state.TenantsData}
                                changeMyTenant={this.wsChangeMyTenant.bind(this)} />}

                    </CommonModal>}
            </React.Fragment>
        );
    }
}
export default withStyles(loginStyle)(Login);