import { makeStyles } from '@material-ui/core/styles';

const filledTextFieldStyle = makeStyles(theme => ({
    textField: {
        color: '#37251C',
        fontFamily: 'Nunito, sans-serif',
        fontWeight:'400',
        fontSize: '16px',
        lineHeight: 1.57,
        textAlign:'left',
    },
    label: {
        color: '#92929d',
        fontFamily: 'Nunito, sans-serif',
        fontWeight: 400,
        fontSize: '16px',
    }
}));

export default filledTextFieldStyle;
