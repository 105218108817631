/* eslint eqeqeq: 0 */
import { URLs } from './URLs';

import { API, HTTPMethod } from "./API";

/**
 * This object represents the error that occurred in any api call that APIController has to offer
 * 
 * @param {String|Number} code Status code
 * @param {String} message Error message
 */
export function APIControllerError(code, message) {
    this.code = code
    this.message = message
}

/**
 * This object represents the output format for all api calls that APIController has to offer
 * 
 * @param {JSON} object - JSON representation of the object
 * @param {JSON|APIControllerError} error - Representation of the error
 */
export function APIControllerResponse(object, error) {
    this.object = object
    this.error = error
}


export function GetAccessToken(username, password) {
    // let data = JSON.parse(values)
    var details = {
        username: username,
        password: password,
        DeviceID: '1111111111-1111111111',
        DeviceTypeID: '3',
        grant_type: "password",
    };
    var formBody = [];
    for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    let url = URLs.base + URLs.Gettoken;
    return new Promise((resolve, reject) => {
        API.shared.request(HTTPMethod.post, url, formBody)
            .then((response) => {

                if (response && response.StatusCode === 401) {
                    let error = new APIControllerError(response.StatusCode, response.Message)
                    resolve(new APIControllerResponse(null, error))
                } else if (response && response.access_token) {
                    resolve(new APIControllerResponse(response, null))
                    localStorage.setItem("Access_Token", response.access_token);
                } else if (response.error_description != '') {
                    // console.log('response:', response)
                    let error = new APIControllerError(response)
                    console.log('error:', error)
                    resolve(new APIControllerResponse(null, error))
                }
                // {"error":"Invalid User","error_description":"Invalid User"}
            })
            .catch((error) => {
                resolve(new APIControllerResponse(null, error))
            });
    });
}

export function RegisterBrowserNotificationToken(Token) {
    let params = JSON.stringify({ 'Token': Token })
    let url = URLs.base + URLs.RegisterBrowserNotificationToken;

    return new Promise((resolve, reject) => {
        API.shared.request(HTTPMethod.post, url, params).then((response) => {
            if (response && response.StatusCode === 401) {
                let error = new APIControllerError(response.StatusCode, response.Message)
                resolve(new APIControllerResponse(null, error))
            }
            else if (response && response.data) {
                resolve(new APIControllerResponse(response, null))
            }
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function VerifyToken() {
    let params = JSON.stringify({})
    let url = URLs.base + URLs.VerifyToken;

    return new Promise((resolve, reject) => {
        API.shared.request(HTTPMethod.post, url, params).then((response) => {
            if (response && response.StatusCode === 401) {
                let error = new APIControllerError(response.StatusCode, response.Message)
                resolve(new APIControllerResponse(null, error))
            }
            else if (response && response.data) {
                resolve(new APIControllerResponse(response, null))
            }
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function VerifyOTP() {
    let params = JSON.stringify({})
    let url = URLs.base + URLs.VerifyOTP;

    return new Promise((resolve, reject) => {
        API.shared.request(HTTPMethod.post, url, params).then((response) => {
            if (response && response.StatusCode === 401) {
                let error = new APIControllerError(response.StatusCode, response.Message)
                resolve(new APIControllerResponse(null, error))
            }
            else if (response && response.data) {
                resolve(new APIControllerResponse(response, null))
            }
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function ResendOTP() {
    let params = JSON.stringify({})
    let url = URLs.base + URLs.ResendOTP;

    return new Promise((resolve, reject) => {
        API.shared.request(HTTPMethod.post, url, params).then((response) => {
            if (response && response.StatusCode === 401) {
                let error = new APIControllerError(response.StatusCode, response.Message)
                resolve(new APIControllerResponse(null, error))
            }
            else if (response && response.data) {
                resolve(new APIControllerResponse(response, null))
            }
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function GetMyTenants() {
    let params = JSON.stringify({})
    let url = URLs.base + URLs.GetMyTenants;

    return new Promise((resolve, reject) => {
        API.shared.request(HTTPMethod.post, url, params).then((response) => {
            if (response && response.StatusCode === 401) {
                let error = new APIControllerError(response.StatusCode, response.Message)
                resolve(new APIControllerResponse(null, error))
            }
            else if (response && response.data) {
                resolve(new APIControllerResponse(response, null))
            }
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function ChangeMyTenant(TenantID) {
    let params = JSON.stringify({ "TenantID": TenantID })
    let url = URLs.base + URLs.ChangeMyTenant;

    return new Promise((resolve, reject) => {
        API.shared.request(HTTPMethod.post, url, params).then((response) => {
            if (response && response.StatusCode === 401) {
                let error = new APIControllerError(response.StatusCode, response.Message)
                resolve(new APIControllerResponse(null, error))
            } else if (response && response.access_token) {
                resolve(new APIControllerResponse(response, null))
                localStorage.setItem("Access_Token", response.access_token);
            }
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function ForgotPassword(MobileNo) {
    let params = JSON.stringify({
        'MobileNo': MobileNo,
    })
    let url = URLs.base + URLs.ForgotPassword;

    return new Promise((resolve, reject) => {
        API.shared.request(HTTPMethod.post, url, params).then((response) => {
            if (response && response.StatusCode === 401) {
                let error = new APIControllerError(response.StatusCode, response.Message)
                resolve(new APIControllerResponse(null, error))
            }
            else if (response && response.data) {
                resolve(new APIControllerResponse(response, null))
            }
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function ChangePassword(Password, NewPassword) {
    let params = JSON.stringify({
        'Password': Password,
        'NewPassword': NewPassword
    })
    let url = URLs.base + URLs.ChangePassword;

    return new Promise((resolve, reject) => {
        API.shared.request(HTTPMethod.post, url, params).then((response) => {
            if (response && response.StatusCode === 401) {
                let error = new APIControllerError(response.StatusCode, response.Message)
                resolve(new APIControllerResponse(null, error))
            }
            else if (response && response.data) {
                resolve(new APIControllerResponse(response, null))
            }
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function SetPassword(AccessCode, Password) {
    let params = JSON.stringify({
        'AccessCode': AccessCode,
        'Password': Password
    })
    let url = URLs.base + URLs.SetPassword;

    return new Promise((resolve, reject) => {
        API.shared.request(HTTPMethod.post, url, params).then((response) => {
            if (response && response.StatusCode === 401) {
                let error = new APIControllerError(response.StatusCode, response.Message)
                resolve(new APIControllerResponse(null, error))
            }
            else if (response && response.data) {
                resolve(new APIControllerResponse(response, null))
            }
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function GetMyNotifications() {
    let params = JSON.stringify({})
    let url = URLs.base + URLs.GetMyNotifications;

    return new Promise((resolve, reject) => {
        API.shared.request(HTTPMethod.post, url, params).then((response) => {
            if (response && response.StatusCode === 401) {
                let error = new APIControllerError(response.StatusCode, response.Message)
                resolve(new APIControllerResponse(null, error))
            }
            else if (response && response.data) {
                resolve(new APIControllerResponse(response, null))
            }
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function GetCartForDropdown(StoreID) {
    let params = JSON.stringify({ "StoreID": StoreID })
    let url = URLs.base + URLs.GetCartForDropdown;

    return new Promise((resolve, reject) => {
        API.shared.request(HTTPMethod.post, url, params).then((response) => {
            if (response && response.StatusCode === 401) {
                let error = new APIControllerError(response.StatusCode, response.Message)
                resolve(new APIControllerResponse(null, error))
            }
            else if (response && response.data) {
                resolve(new APIControllerResponse(response, null))
            }
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function SearchMedicines(Name) {
    let params = JSON.stringify({ 'Name': Name })
    let url = URLs.base + URLs.SearchMedicines;

    return new Promise((resolve, reject) => {
        API.shared.request(HTTPMethod.post, url, params).then((response) => {
            if (response && response.StatusCode === 401) {
                let error = new APIControllerError(response.StatusCode, response.Message)
                resolve(new APIControllerResponse(null, error))
            }
            else if (response && response.data) {
                resolve(new APIControllerResponse(response, null))
            }
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function AddEditCart(ID, MedicineID, Quantity) {
    let params = JSON.stringify({
        "ID": ID,
        "MedicineID": MedicineID,
        "Quantity": Quantity
    })
    let url = URLs.base + URLs.AddEditCart;

    return new Promise((resolve, reject) => {
        API.shared.request(HTTPMethod.post, url, params).then((response) => {
            if (response && response.StatusCode === 401) {
                let error = new APIControllerError(response.StatusCode, response.Message)
                resolve(new APIControllerResponse(null, error))
            }
            else if (response && response.data) {
                resolve(new APIControllerResponse(response, null))
            }
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function DeleteCart(ID) {
    let params = JSON.stringify({ "ID": ID })
    let url = URLs.base + URLs.DeleteCart;

    return new Promise((resolve, reject) => {
        API.shared.request(HTTPMethod.post, url, params).then((response) => {
            if (response && response.StatusCode === 401) {
                let error = new APIControllerError(response.StatusCode, response.Message)
                resolve(new APIControllerResponse(null, error))
            }
            else if (response && response.data) {
                resolve(new APIControllerResponse(response, null))
            }
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function PlaceOrder(StoreID, DeviceTypeID, DeliveryInstructions) {
    let params = JSON.stringify({
        "StoreID": StoreID,
        "DeviceTypeID": DeviceTypeID,
        "DeliveryInstructions": DeliveryInstructions
    })
    let url = URLs.base + URLs.PlaceOrder;

    return new Promise((resolve, reject) => {
        API.shared.request(HTTPMethod.post, url, params).then((response) => {
            if (response && response.StatusCode === 401) {
                let error = new APIControllerError(response.StatusCode, response.Message)
                resolve(new APIControllerResponse(null, error))
            }
            else if (response && response.data) {
                resolve(new APIControllerResponse(response, null))
            }
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export async function OrderDetails(ID) {
    let params = JSON.stringify({ "ID": ID })
    let url = URLs.base + URLs.OrderDetails;

    return new Promise((resolve, reject) => {
        API.shared.request(HTTPMethod.post, url, params).then((response) => {
            if (response && response.StatusCode === 401) {
                let error = new APIControllerError(response.StatusCode, response.Message)
                resolve(new APIControllerResponse(null, error))
            }
            else if (response && response.data) {
                resolve(new APIControllerResponse(response, null))
            }
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function GetMyOrders() {
    let params = JSON.stringify({})
    let url = URLs.base + URLs.GetMyOrders;

    return new Promise((resolve, reject) => {
        API.shared.request(HTTPMethod.post, url, params).then((response) => {
            if (response && response.StatusCode === 401) {
                let error = new APIControllerError(response.StatusCode, response.Message)
                resolve(new APIControllerResponse(null, error))
            }
            else if (response && response.data) {
                resolve(new APIControllerResponse(response, null))
            }
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function GetUserStores() {
    let params = JSON.stringify({})
    let url = URLs.base + URLs.GetUserStores;

    return new Promise((resolve, reject) => {
        API.shared.request(HTTPMethod.post, url, params).then((response) => {
            if (response && response.StatusCode === 401) {
                let error = new APIControllerError(response.StatusCode, response.Message)
                resolve(new APIControllerResponse(null, error))
            }
            else if (response && response.data) {
                resolve(new APIControllerResponse(response, null))
            }
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function GetCompanyForDropdown() {
    let params = JSON.stringify({})
    let url = URLs.base + URLs.GetCompanyForDropdown;

    return new Promise((resolve, reject) => {
        API.shared.request(HTTPMethod.post, url, params).then((response) => {
            if (response && response.StatusCode === 401) {
                let error = new APIControllerError(response.StatusCode, response.Message)
                resolve(new APIControllerResponse(null, error))
            }
            else if (response && response.data) {
                resolve(new APIControllerResponse(response, null))
            }
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function DeleteStore(ID) {
    let params = JSON.stringify({ "ID": ID })
    let url = URLs.base + URLs.DeleteStore;

    return new Promise((resolve, reject) => {
        API.shared.request(HTTPMethod.post, url, params).then((response) => {
            if (response && response.StatusCode === 401) {
                let error = new APIControllerError(response.StatusCode, response.Message)
                resolve(new APIControllerResponse(null, error))
            }
            else if (response && response.data) {
                resolve(new APIControllerResponse(response, null))
            }
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function AddEditStore(ID, StoreName, Address1, Address2, Postcode, EmailAddress, MobileNo, CityID, IsActive, IsDefault) {
    let params = JSON.stringify({
        'ID': ID,
        'StoreName': StoreName,
        'Address1': Address1,
        'Address2': Address2,
        'Postcode': Postcode,
        'EmailAddress': EmailAddress,
        'MobileNo': MobileNo,
        'CityID': CityID,
        'IsActive': IsActive,
        'IsDefault': IsDefault,
    })
    let url = URLs.base + URLs.AddEditStore;

    return new Promise((resolve, reject) => {
        API.shared.request(HTTPMethod.post, url, params).then((response) => {
            if (response && response.StatusCode === 401) {
                let error = new APIControllerError(response.StatusCode, response.Message)
                resolve(new APIControllerResponse(null, error))
            }
            else if (response && response.data) {
                resolve(new APIControllerResponse(response, null))
            }
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function GetStoreByID(ID) {
    let params = JSON.stringify({ "ID": ID })
    let url = URLs.base + URLs.GetStoreByID;

    return new Promise((resolve, reject) => {
        API.shared.request(HTTPMethod.post, url, params).then((response) => {
            if (response && response.StatusCode === 401) {
                let error = new APIControllerError(response.StatusCode, response.Message)
                resolve(new APIControllerResponse(null, error))
            }
            else if (response && response.data) {
                resolve(new APIControllerResponse(response, null))
            }
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}


export function GetMyPickups() {
    let params = JSON.stringify({})
    let url = URLs.base + URLs.GetMyPickups;

    return new Promise((resolve, reject) => {
        API.shared.request(HTTPMethod.post, url, params).then((response) => {
            if (response && response.StatusCode === 401) {
                let error = new APIControllerError(response.StatusCode, response.Message)
                resolve(new APIControllerResponse(null, error))
            }
            else if (response && response.data) {
                resolve(new APIControllerResponse(response, null))
            }
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function AddEditPickup(ID, UserID, StoreID, Remarks) {
    let params = JSON.stringify({
        "ID": ID,
        "UserID": UserID,
        "StoreID": StoreID,
        "Remarks": Remarks,
    })
    let url = URLs.base + URLs.AddEditPickup;

    return new Promise((resolve, reject) => {
        API.shared.request(HTTPMethod.post, url, params).then((response) => {
            if (response && response.StatusCode === 401) {
                let error = new APIControllerError(response.StatusCode, response.Message)
                resolve(new APIControllerResponse(null, error))
            }
            else if (response && response.data) {
                resolve(new APIControllerResponse(response, null))
            }
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}
export function GetAllTenants() {
    let params = JSON.stringify({})
    let url = URLs.base + URLs.GetAllTenants;

    return new Promise((resolve, reject) => {
        API.shared.request(HTTPMethod.post, url, params).then((response) => {
            if (response && response.StatusCode === 401) {
                let error = new APIControllerError(response.StatusCode, response.Message)
                resolve(new APIControllerResponse(null, error))
            }
            else if (response && response.data) {
                resolve(new APIControllerResponse(response, null))
            }
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

export function AddStoreToTenant(TenantID, StoreID) {
    let params = JSON.stringify({
        "TenantID": TenantID,
        "StoreID": StoreID
    })
    let url = URLs.base + URLs.AddStoreToTenant;

    return new Promise((resolve, reject) => {
        API.shared.request(HTTPMethod.post, url, params).then((response) => {
            if (response && response.StatusCode === 401) {
                let error = new APIControllerError(response.StatusCode, response.Message)
                resolve(new APIControllerResponse(null, error))
            }
            else if (response && response.data) {
                resolve(new APIControllerResponse(response, null))
            }
        }).catch((error) => {
            resolve(new APIControllerResponse(null, error))
        })
    })
}

// export function GetMyTenants() {
// 	let params = JSON.stringify({})
// 	let url = URLs.base + URLs.GetMyTenants;

// 	return new Promise((resolve, reject) => {
// 		API.shared.request(HTTPMethod.post, url, params).then((response) => {
// 			if (response && response.StatusCode === 401) {
// 				let error = new APIControllerError(response.StatusCode, response.Message)
// 				resolve(new APIControllerResponse(null, error))
// 			}
// 			else if (response && response.data) {
// 				resolve(new APIControllerResponse(response, null))
// 			}
// 		}).catch((error) => {
// 			resolve(new APIControllerResponse(null, error))
// 		})
// 	})
// }

// export function ChangeMyTenant(TenantID) {
// 	let params = JSON.stringify({ "TenantID": TenantID })
// 	let url = URLs.base + URLs.ChangeMyTenant;

// 	return new Promise((resolve, reject) => {
// 		API.shared.request(HTTPMethod.post, url, params).then((response) => {
// 			if (response && response.StatusCode === 401) {
// 				let error = new APIControllerError(response.StatusCode, response.Message)
// 				resolve(new APIControllerResponse(null, error))
// 			} else if (response && response.access_token) {
// 				resolve(new APIControllerResponse(response, null))
// 			}
// 		}).catch((error) => {
// 			resolve(new APIControllerResponse(null, error))
// 		})
// 	})
// }
