import React from 'react'; import { Typography, Grid } from '@material-ui/core';
import Scrollbars from 'react-custom-scrollbars';

const Notifications = props => {
    return (
        <div style={{ width: '500px', height: "400px", maxHeight: "400px", }}>
            {!props.NotificationData.length
                ? <Grid container style={{ width: '100%', bottom: '0%', top: '0%', left: '0%', right: '0%' }} direction="row" justify='center' alignItems='center'>
                    <Typography style={{ fontSize: '18px', fontWeight: '600', fontFamily: 'Nunito, sans-serif', textAlign: 'center' }}> No Notification Found </Typography>
                </Grid>
                : <Scrollbars style={{ maxHeight: 385, width: '100%', }}
                    autoHeightMax={800}
                    autoHide hideTracksWhenNotNeeded={true} >
                    {props.NotificationData.map((item, index) =>
                        <div style={{ border: '1px solid #000', borderRadius: '5px', width: '100%', fontSize: "13px", maxHeight: "235px", overflowY: 'auto', marginBottom: '2px', }}>
                            <Grid container direction='column' style={{ padding: '1%' }}>
                                <Grid container direction='row' justify='space-between' alignItems='center' style={{ width: '100%', }}>
                                    <Grid container justify='center' alignItems='center' style={{ width: '35%', }}>
                                        <Typography style={{ fontSize: '16px', fontWeight: '700', fontFamily: 'Nunito, sans-serif', color: '#37251C', }}>{item.TemplateName}</Typography>
                                    </Grid>
                                    <Grid container direction='column' justify='flex-start' style={{ width: '63%' }}>
                                        <Typography style={{ fontSize: '16px', fontWeight: '700', fontFamily: 'Nunito, sans-serif', color: '#37251C', }}>{item.FullName}</Typography>
                                        <Typography style={{ fontSize: '14px', fontWeight: '400', fontFamily: 'Nunito, sans-serif', color: '#37251C', }}>{item.NotificationAt}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container justify='flex-start' alignItems='center' style={{ marginTop: '1%', marginBottom: '1%', maxWidth: "700px", overflowX: 'hidden' }}>
                                    <Typography noWrap={true} style={{ fontSize: '12px', fontWeight: '400', fontFamily: 'Nunito, sans-serif', color: '#37251C', }}>{item.Description}</Typography>
                                </Grid>
                            </Grid>
                        </div>
                    )}
                </Scrollbars>}
        </div >
    );
};

export default Notifications;
