/* eslint eqeqeq: 0 */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import SetPasswordStyle from './SetPasswordStyle';
import * as APIController from '../../api/APIController';
import { Images } from '../../common';
import { CssBaseline, Grid, Typography, InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FilledTextField from '../../components/FilledTextField/FilledTextField';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import { ToastsContainer, ToastsStore } from 'react-toasts';

class SetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accesscode: '',
            isLoading: false,
            mediumBrowser: 'false',
            showPassword: false,
            showCPassword: false,
            Password: "",
            ConfirmPassword: "",
        };
    }
    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        this.setState({ accesscode: window.location.search.split('=').pop() });
    } resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 })
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    validateForm() {
        if (this.state.Password.length <= 5) {
            ToastsStore.error('Password should be minimum 6 characters long');
        } else if (this.state.ConfirmPassword.length <= 5) {
            ToastsStore.error('Confirm Password should be minimum 6 characters long')
        } else if (this.state.Password !== this.state.ConfirmPassword) {
            ToastsStore.error('Password Does not match')
        } else {
            this.wsSetPassword(this.state.accesscode, this.state.ConfirmPassword)
        }
    }

    wsSetPassword = (AccessCode, Password) => {
        this.setState({ isLoading: true })
        APIController.SetPassword(AccessCode, Password)
            .then((response) => {
                this.setState({ isLoading: false })
                if (response.object.status != 0) {
                    ToastsStore.success(response.object.message)
                    setTimeout(() => {
                        window.location.replace(response.object.data)
                    }, 4000);
                } else {
                    ToastsStore.error(response.object.message)
                }
            })
    }

    renderForm() {
        const { showPassword, showCPassword, isLoading } = this.state;
        const { classes } = this.props;
        return (
            <form noValidate onSubmit={(e) => { e.preventDefault(); this.validateForm(); }}>
                <Grid container alignItems="center" justify={'center'}>
                    <FilledTextField style={{ marginTop: '5%', }} type={showPassword ? 'text' : 'password'} label={'Password'} name="Password" onChange={this.handleChange} rows={1}
                        InputProps={{
                            className: classes.textField,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => this.setState({ showPassword: !this.state.showPassword })} >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }} />
                    <FilledTextField style={{ marginTop: '5%', }} type={showCPassword ? 'text' : 'password'} label={'Confirm Password'} name="ConfirmPassword" onChange={this.handleChange} rows={1}
                        InputProps={{
                            className: classes.textField,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => this.setState({ showCPassword: !this.state.showCPassword })} >
                                        {showCPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }} />

                    <Grid container direction='row' justify={'space-between'} alignItems='center' style={{ width: '100%', margin: '8% 0' }}>
                        <ButtonBase type="submit" title={'Save'} style={{ width: '100%' }} isLoading={isLoading} />
                    </Grid>
                </Grid>
            </form >
        );
    }


    render() {
        const { classes } = this.props;
        const { mediumBrowser } = this.state;
        return (
            <React.Fragment>
                <CssBaseline />
                <ToastsContainer store={ToastsStore} />
                <main className={classes.main}>
                    <Grid container justify="flex-end" alignItems='center' style={{ width: '98%', top: 0, bottom: 0, left: 0, right: 0 }} >
                        <Grid container className={classes.mainContainer} style={mediumBrowser ? { width: '100%', backgroundColor: "#fff" } : { width: '30%', backgroundColor: "#fff" }} >
                            <Grid container justify={'center'}>
                                <img src={Images.logoImage} style={{ margin: '5% 0' }} alt="" />
                            </Grid>
                            <Grid container style={{ margin: "0 5%" }} >
                                <Typography className={classes.txtLogin}>Set Password</Typography>
                                {this.renderForm()}
                            </Grid>
                        </Grid>
                    </Grid>
                </main>
            </React.Fragment>
        );
    }
}
export default withStyles(SetPasswordStyle)(SetPassword);