/* eslint eqeqeq: 0 */
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';

function CommonModal(props) {
    const { Title, onClose, maxWidth, IsScroll, children, OrderNO } = props;
    const handleClose = () => {
        onClose(false)
    };

    // let lastWord = Title.substring(Title.lastIndexOf(" ") + 1);

    return (
        <div >
            <Dialog
                open={true}
                maxWidth={maxWidth}
                onClose={props.isOuterClick == undefined ? handleClose : props.isOuterClick}
                scroll={IsScroll != undefined ? 'body' : 'paper'}
                aria-labelledby="scroll-dialog-title">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <DialogTitle style={{ marginBottom: '0px', padding: '10px 24px 0', fontFamily: 'Nunito, sans-serif' }} id="scroll-dialog-title">{Title}</DialogTitle>
                    {OrderNO != "" && OrderNO != undefined && OrderNO != null ? <DialogTitle style={{ marginBottom: '0px', padding: '10px 24px 0', fontFamily: 'Nunito, sans-serif' }} >{OrderNO}</DialogTitle>
                        : <IconButton onClick={() => onClose(true)} >
                            <Close />
                        </IconButton>}
                </div>
                <DialogContent style={{ overflow: 'hidden', }} >
                    <DialogContentText>
                        {children}
                    </DialogContentText>
                </DialogContent>

            </Dialog>

        </div>
    );

}

export default CommonModal;