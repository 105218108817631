import firebase from 'firebase';

export const initializeFirebase = () => {
  firebase.initializeApp({
    apiKey: "AIzaSyDswDloxp4Iz4b2LdpqzRuUUbT88y63pGY",
    authDomain: "chemisto-95f61.firebaseapp.com",
    projectId: "chemisto-95f61",
    storageBucket: "chemisto-95f61.appspot.com",
    messagingSenderId: "844153240620",
    appId: "1:844153240620:web:43d2de3cced47edff66589",
    measurementId: "G-SSMWHSB79Q"
  });
}

export const askForPermissioToReceiveNotifications = async () => {
  try {
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();
    localStorage.setItem('FirebaseToken', token)
    return token;
  } catch (error) {  }
}
export const onMessageListener = () =>

  new Promise((resolve) => {
    firebase.messaging().onMessage((payload) => {
      resolve(payload);
    });
  });
