import React, { useState, useEffect } from 'react';
// import disableBrowserBackButton from 'disable-browser-back-navigation';
import { Grid, Paper, Typography, Tabs, Tab, withStyles } from '@material-ui/core';
import CommonStyle from "./CommonStyle";
import * as APIController from '../../api/APIController';
import authService from "../../services/auth-service";
import CommonLoader from "../../components/CommonLoader/CommonLoader";
import jwt_decode from "jwt-decode";

const TenantFormComponent = ({ classes }) => {
    const [tenantsData, setTenantsData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedMasterTenant, setSelectedMasterTenant] = useState(null);
    const [selectedTab, setSelectedTab] = useState(0);

    useEffect(() => {
        // disableBrowserBackButton();
        wsGetMyTenants();
    }, []);

    const wsGetMyTenants = () => {
        APIController.GetMyTenants()
            .then((response) => {
                if (response.error == null) {
                    const data = response.object.data;
                    setTenantsData(data);
                    setIsLoading(false);
                    if (data.length > 0) {
                        const firstMasterTenant = data.find(tenant => tenant.MasterTenant === null);
                        if (firstMasterTenant) {
                            setSelectedMasterTenant(firstMasterTenant.Name);
                            setSelectedTab(0);
                        }
                    }
                }
            })
    };

    const wsChangeMyTenant = (tenantID) => {
        APIController.ChangeMyTenant(tenantID)
            .then((response) => {
                const decoded = jwt_decode(response.object.jwt);
                localStorage.setItem('localStorageTN', decoded.TN);
                // localStorage.setItem('UserData',decoded.IL)
                localStorage.setItem("Access_Token", response.object.access_token);
                authService.setAuthData(response.object);
                window.location.replace('/Dashboard')
                // wsGetNav();
            })

    };

    // const wsGetNav = () => {
    //     APIController.GetNav()
    //         .then((response) => {
    //             if (response.error == null) {
    //                 localStorage.setItem("localStorageGetNav", JSON.stringify(response.object.data));
    //                 window.location.replace('/app/Orders');
    //             }
    //         })
    // };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        setSelectedMasterTenant(masterTenants[newValue].Name);
    };

    const handleTenantClick = (tenantID, tenantName) => {
        localStorage.setItem('TenantID', tenantID);
        localStorage.setItem('TenantName', tenantName);
        wsChangeMyTenant(tenantID);
    };

    const masterTenants = tenantsData.filter(tenant => tenant.MasterTenant === null);
    const subTenants = tenantsData.filter(tenant => (tenant.MasterTenant === selectedMasterTenant || tenant.Name === selectedMasterTenant));

    return (
        <Grid container style={{ overflowY: 'auto', width: "850px", height: "350px", alignItems: "baseline", alignContent: "flex-start" }}>
            {isLoading ? <CommonLoader /> :
                <>
                    <Tabs
                        style={{ border: "1px solid #283593", width: "100%" }}
                        value={selectedTab}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="master tenants tabs"
                    >
                        {masterTenants.map((value, index) => (
                            <Tab style={{ fontSize: "16px" }} key={index} label={value.Name} />
                        ))}
                    </Tabs>
                    {selectedMasterTenant && (
                        <Grid container style={{ marginTop: "2%" }}>
                            {subTenants.map((value, index) => (
                                <Paper
                                    key={index}
                                    className={classes.CardStyle}
                                    elevation={2}
                                    style={{ cursor: "pointer", padding: '10px 18px', textAlign: 'center', margin: '8px', backgroundColor: '#fff', color: "#152b60" }}
                                    onClick={() => handleTenantClick(value.ID, value.Name)}>
                                    <Grid item justify="center" alignItems="start" style={{ elevation: 10 }}>
                                        {/* <img alt="img" src={value.LogoURL} style={{ height: '100px', width: '100px' }} /> */}
                                        <Typography display='block' style={{ fontSize: "14px" }} className={classes.CartTextStyle}> {value.Name}</Typography>
                                    </Grid>
                                </Paper>
                            ))}
                        </Grid>
                    )}
                </>
            }
        </Grid>
    );
};

export default withStyles(CommonStyle)(TenantFormComponent);
