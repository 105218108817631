/* eslint eqeqeq: 0 */
import React, { Component } from 'react';
import * as APIController from '../../api/APIController.js';
import Grid from '@material-ui/core/Grid';
import { CircularProgress } from '@material-ui/core'
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '../../components/ButtonBase/ButtonBase';

const styles = {
    chartFluid: {
        width: '100%',
        minWidth: 200,
        height: 250,
        margin: '0px 5px',
    },
    lables: {
        color: '#fff',
        fontWeight: 500,
        padding: "10px",
    },
    mainDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        color: '#000',
    },
    evenDiv: {
        backgroundColor: '#f9f9f9',
        // borderBottom: "1px solid #000",
        padding: '5px',
        '& label': {
            fontSize: '14px'
        }
    },
    oddDiv: {
        backgroundColor: '#fff',
        // borderBottom: "1px solid #000",
        padding: '5px',
        '& label': {
            fontSize: '14px'
        }
    },
};

class OrderDetails extends Component {
    state = {
        OrderData: [],
        OrderDetailsData: [],
        ExportDetailsData: [],
        IsActive: true,
        Driver: '',
        touched: false,
        IsValid: '',
        option: [],
        IsLoading: false,
        IsloadData: false
    }

    componentDidMount() {
        this.wsOrderDetails(this.props.OrderID)
    }

    wsOrderDetails(ID) {
        APIController.OrderDetails(ID)
            .then((response) => {
                this.setState({ open: false })
                if (response.object.status != "0") {
                    this.setState({ OrderData: response.object.data })

                    let finaldata = response.object.data.OrderDetails
                    let finaldata1 = response.object.data.ExportDetails
                    finaldata.unshift({ 'Name': 'Name', 'Quantity': 'Quantity', 'SalePrice': 'Sale Price', 'MRP': 'MRP', 'TotalSalePrice': 'Total Sale Price', 'TotalMRP': 'Total MRP' })
                    finaldata1.unshift({ 'MedicineName': 'Medicine Name', 'DealerName': 'Dealer Name', 'Quantity': 'Quantity', 'ExportedAt': 'Exported At' })

                    this.setState({ OrderDetailsData: finaldata, ExportDetailsData: finaldata1 })
                }
                else if (response.error.message && response.error.message.length > 0) {
                    // this.handleNotification(true, response.error.message, 'error')
                }
                this.setState({ IsloadData: false })
            })
    }

    render() {
        const { classes } = this.props;
        if (this.state.IsloadData)
            return <Grid container style={{ width: '100%' }} direction={'row'} justify={'center'} alignItems='center'>
                <CircularProgress />
            </Grid>
        else return (
            <>
                {this.state.OrderData != "" &&
                    <div style={{ width: '100%' }}>
                        <div style={{ width: '700px', direction: 'flex', justifyContent: 'spaceBetween', color: '#000', fontSize: '16px' }}>
                            <p>{this.state.OrderData.Order[0].StoreName}&nbsp;|&nbsp;
                                {this.state.OrderData.Order[0].ContactName}&nbsp;|&nbsp;
                                {this.state.OrderData.Order[0].OrderAt}&nbsp;|&nbsp;
                                {this.state.OrderData.Order[0].MobileNo}</p>
                        </div>
                        <div style={{ border: '1px solid #3c8dbc', borderRadius: '5px', width: '100%', fontSize: "13px" }}>
                            <div style={{ backgroundColor: '#3c8dbc' }}>
                                <label style={{ color: '#fff', fontWeight: 500, padding: "10px", }}>Order Details : {this.state.OrderData.Order[0].Address},&nbsp;{this.state.OrderData.Order[0].CityName}</label>
                            </div>
                            <div style={{ maxHeight: "235px", overflowY: 'auto', marginBottom: '2px', }}>
                                {this.state.OrderDetailsData.length && this.state.OrderDetailsData.map((item, index) => {
                                    if (index == 0) {
                                        return (
                                            <div className={classes.mainDiv} style={{ borderBottom: '1px solid #000', padding: '0px 3px', fontWeight: 500 }}>
                                                <label style={{ width: '45%' }} >{item.Name}</label>
                                                <label style={{ width: '9%', textAlign: 'center' }}>{item.Quantity}</label>
                                                <label style={{ width: '11%', textAlign: 'right' }}>{item.SalePrice}</label>
                                                <label style={{ width: '9%', textAlign: 'right' }}>{item.MRP}</label>
                                                <label style={{ width: '15%', textAlign: 'right' }}>{item.TotalSalePrice}</label>
                                                <label style={{ width: '11%', textAlign: 'right' }}>{item.TotalMRP}</label>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div className={!(index % 2) ? classes.oddDiv : classes.evenDiv} >
                                                <div className={classes.mainDiv}>
                                                    <label style={{ width: '45%', fontSize: "13px" }} >{item.Name}</label>
                                                    <label style={{ width: '9%', textAlign: 'center' }}>{item.Quantity}</label>
                                                    <label style={{ width: '11%', textAlign: 'right' }}>{item.SalePrice}</label>
                                                    <label style={{ width: '9%', textAlign: 'right' }}>{item.MRP}</label>
                                                    <label style={{ width: '15%', textAlign: 'right' }}>{item.TotalSalePrice}</label>
                                                    <label style={{ width: '11%', textAlign: 'right' }}>{item.TotalMRP}</label>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                        {/* #24a38c */}

                        <div style={{ border: '1px solid #f39c12', borderRadius: '5px', width: '100%', margin: '10px 0px', fontSize: "13px" }}>
                            <div style={{ backgroundColor: '#f39c12' }}>
                                <label style={{ color: '#fff', fontWeight: 500, padding: "10px", }}>Instructions</label>
                            </div>
                            <div style={{ display: 'flex', padding: "5px", fontSize: "13px" }}>
                                <div style={{ width: "33.33%", color: '#000' }}>
                                    <p style={{ fontWeight: 500, margin: '0' }}>Estimated Delivery Date</p>
                                    <p style={{ fontWeight: 400, margin: "0" }}>{this.state.OrderData.Order[0].EstimatedDeliveryDate == null ? '-' : this.state.OrderData.Order[0].EstimatedDeliveryDate.split(' ')[0]}</p>
                                </div>
                                <div style={{ width: "66.66%", color: '#000' }}>
                                    <p style={{ fontWeight: 500, margin: '0' }}>Delivery Instructions </p>
                                    <p style={{ fontWeight: 400, margin: "0" }}>{this.state.OrderData.Order[0].DeliveryInstructions}</p>
                                </div>
                            </div>
                        </div>
                        <div style={{ border: '1px solid #00a65a', borderRadius: '5px', width: '100%', margin: '10px 0px', fontSize: "13px" }}>
                            <div style={{ backgroundColor: '#00a65a' }}>
                                <label style={{ color: '#fff', fontWeight: 500, padding: "10px", }}>Time Line</label>
                            </div>
                            <div style={{ display: 'flex', padding: "5px", fontSize: "13px", }}>
                                <div style={{ width: "33.33%", color: '#000' }}>
                                    <p style={{ fontWeight: 500, margin: '0' }}>Order At</p>
                                    <p style={{ fontWeight: 400, margin: "0" }}>{this.state.OrderData.Order[0].OrderAt == null ? '-' : this.state.OrderData.Order[0].OrderAt}</p>
                                </div>
                                <div style={{ width: "33.33%", color: '#000' }}>
                                    <p style={{ fontWeight: 500, margin: '0' }}>Packed At</p>
                                    <p style={{ fontWeight: 400, margin: "0" }}>{this.state.OrderData.Order[0].PackedAt == null ? '-' : this.state.OrderData.Order[0].PackedAt}</p>
                                </div>
                                <div style={{ width: "33.33%", color: '#000' }}>
                                    <p style={{ fontWeight: 500, margin: '0' }}>Despatched At</p>
                                    <p style={{ fontWeight: 400, margin: "0" }}>{this.state.OrderData.Order[0].DespatchedAt == null ? '-' : this.state.OrderData.Order[0].DespatchedAt}</p>
                                </div>
                            </div>
                            <div style={{ display: 'flex', padding: "5px", fontSize: "13px", }}>
                                <div style={{ width: "33.33%", color: '#000' }}>
                                    <p style={{ fontWeight: 500, margin: '0' }}>Delivery Assigned At</p>
                                    <p style={{ fontWeight: 400, margin: "0" }}>{this.state.OrderData.Order[0].DeliveryAssignedAt == null ? '-' : this.state.OrderData.Order[0].DeliveryAssignedAt}</p>
                                </div>
                                <div style={{ width: "33.33%", color: '#000' }}>
                                    <p style={{ fontWeight: 500, margin: '0' }}>Delivered At</p>
                                    <p style={{ fontWeight: 400, margin: "0" }}>{this.state.OrderData.Order[0].DeliveredAt == null ? '-' : this.state.OrderData.Order[0].DeliveredAt}</p>
                                </div>
                                <div style={{ width: "33.33%", color: '#000' }}>
                                    <p style={{ fontWeight: 500, margin: '0' }}>Payment At</p>
                                    <p style={{ fontWeight: 400, margin: "0" }}>{this.state.OrderData.Order[0].PaymentAt == null ? '-' : this.state.OrderData.Order[0].PaymentAt}</p>
                                </div>
                            </div>
                        </div>

                        {this.state.OrderData.Order[0].RejectionReason != null &&
                            <div style={{ border: '1px solid #b33434', borderRadius: '5px', width: '100%', maxHeight: '100px', overflowY: "auto", fontSize: "13px", }}>
                                <div className={classes.mainDiv} style={{ backgroundColor: '#b33434' }}>
                                    <label style={{ color: '#fff', fontWeight: 500, padding: "0 10px", }}>Rejection Details</label>
                                    {this.state.OrderData.Order[0].CancelledAt != null &&
                                        <label style={{ color: '#fff', fontWeight: 500, padding: "0 10px", }}>Cancelled At : {this.state.OrderData.Order[0].CancelledAt}</label>}
                                </div>
                                <div style={{ display: 'flex', padding: "5px", fontSize: "13px", }}>
                                    <div style={{ width: "33.33%", color: '#000' }}>
                                        <p style={{ fontWeight: 500, margin: '0px' }}>Rejected At</p>
                                        <p style={{ fontWeight: 400, margin: "0" }}>{this.state.OrderData.Order[0].RejectedAt == null ? '-' : this.state.OrderData.Order[0].RejectedAt}</p>
                                    </div>
                                    <div style={{ width: "33.33%", color: '#000' }}>
                                        <p style={{ fontWeight: 500, margin: '0px' }}>Rejected By</p>
                                        <p style={{ fontWeight: 400, margin: "0" }}>{this.state.OrderData.Order[0].RejectedBy}</p>
                                    </div>
                                    <div style={{ width: "33.33%", color: '#000' }}>
                                        <p style={{ fontWeight: 500, margin: '0px' }}>Rejection Reason</p>
                                        <p style={{ fontWeight: 400, margin: "0" }}>{this.state.OrderData.Order[0].RejectionReason}</p>
                                    </div>
                                </div>
                            </div>}

                        <Grid container style={{ width: '100%', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                            <DialogActions>
                                <ButtonBase onClick={() => this.props.onClose()} title={'Close'} />
                            </DialogActions>
                        </Grid>
                    </div>
                }</>
        );
    }
}

export default withStyles(styles)(OrderDetails);
