import React from 'react';
import { Select, MenuItem } from '@material-ui/core';

import selectDropDownStyle from './SelectDropDownStyle';

const SelectDropDown = props => {
    const classes = selectDropDownStyle();
    const { placeholder, value, name, options, customClass, onChange } = props;
    return (
        <Select
            value={value}
            onChange={onChange}
            displayEmpty
            name={name}
            autoWidth
            className={`${classes.selectEmpty} ${customClass}`}
            disableUnderline={false} >
            <MenuItem value=""> {placeholder} </MenuItem>
            {options.map((item, index) => {
                return <MenuItem value={item.ID} key={index}>{item.Name}</MenuItem>;
            })}
        </Select>
    );
};

export default SelectDropDown;
