/* eslint eqeqeq: 0 */
import React, { Component } from 'react';
import StoresStyle from './StoresStyle';
import { CssBaseline, Typography, Grid, IconButton, CircularProgress } from '@material-ui/core';
import ButtonBase from '../../components/ButtonBase/ButtonBase';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import * as APIController from '../../api/APIController';
import { withStyles } from '@material-ui/core/styles';
import { Delete, Edit } from '@material-ui/icons';
import FilledTextField from '../../components/FilledTextField/FilledTextField';
import SelectDropDown from '../../components/SelectDropDown/SelectDropDown';

class Stores extends Component {
    constructor(props) {
        super(props);
        this.state = {
            storeID: '0',
            storeName: '',
            adddress1: '',
            address2: '',
            postCode: '',
            email: '',
            mobileNo: '',
            CityID: '',
            IsActive: '',
            IsDefault: '',
            UserStoreData: [],
            isLoading: false,
            mediumBrowser: false,
            storeLoading: true,
            isEditStore: false,
            StoreByIDData: false,
            addNewStore: false,
            dataSourceTenant: [],
            TenantID: '',
            dataSourceStore: [],
            StoreID: '',
        };
        this.wsGetUserStores();
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    wsGetUserStores() {
        APIController.GetUserStores().then((response) => {
            let _data = response.object.data;
            let _Categorydata = [];
            _data.map((item) => {
                _Categorydata.push({ ID: item.StoreID, Name: item.StoreName });
            })
            this.setState({ dataSourceStore: _Categorydata, UserStoreData: _data, storeLoading: false });
        });
        this.wsGetAllTenants();
    }
    wsGetAllTenants() {
        APIController.GetAllTenants().then((response) => {
            this.setState({ dataSourceTenant: response.object.data });
        });
    }
    wsDeleteStore(ID) {
        this.setState({ storeLoading: true })
        APIController.DeleteStore(ID).then((response) => {
            this.setState({ UserStoreData: response.object.data, storeLoading: false });
            this.wsGetUserStores()
        });
    }

    wsGetStoreByID(ID) {
        this.setState({ isEditStore: true, storeLoading: true })
        APIController.GetStoreByID(ID).then((response) => {
            this.setState({
                StoreByIDData: response.object.data[0],
                storeID: ID,
                storeName: response.object.data[0].StoreName,
                adddress1: response.object.data[0].Address1,
                address2: response.object.data[0].Address2,
                postCode: response.object.data[0].Postcode,
                email: response.object.data[0].EmailAddress,
                mobileNo: response.object.data[0].MobileNo,
                CityID: response.object.data[0].CityID,
                IsActive: response.object.data[0].IsActive,
                IsDefault: response.object.data[0].IsDefault,
                storeLoading: false
            });
        });
    }

    wsAddEditStore(ID, StoreName, Address1, Address2, Postcode, EmailAddress, MobileNo) {
        this.setState({ storeLoading: true })
        APIController.AddEditStore(ID, StoreName, Address1, Address2, Postcode, EmailAddress, MobileNo, this.state.CityID, this.state.IsActive, this.state.IsDefault)
            .then((response) => {
                if (response.object.status == 1) {
                    ToastsStore.success(response.object.message);
                } else {
                    ToastsStore.error(response.object.message);
                }
                this.setState({ storeLoading: false, isEditStore: false });
            });
    }

    wsAddStoreToTenant(TenantID, StoreID) {
        this.setState({ storeLoading: true })
        APIController.AddStoreToTenant(TenantID, StoreID)
            .then((response) => {
                if (response.object.status == 1) {
                    ToastsStore.success(response.object.message);
                } else {
                    ToastsStore.error(response.object.message);
                }
                this.setState({ storeLoading: false, isEditStore: false });
            });
    }

    validateForm() {
        var IsValid = 0;
        if (this.state.storeName.trim() === '') {
            ToastsStore.error("Please enter Store Name");
        } else if (this.state.adddress1.trim() === '') {
            ToastsStore.error("Please enter Adddress");
        } else if (this.state.postCode.trim() === '') {
            ToastsStore.error("Please enter postCode");
        } else if (this.state.email.trim() === '') {
            ToastsStore.error("Please enter email address");
        } else if (this.state.mobileNo.trim() === '') {
            ToastsStore.error("Please enter mobile number");
        } else {
            IsValid = 1;
        }
        return IsValid;
    }

    handleSubmit() {
        if (this.validateForm()) {
            this.wsAddEditStore(this.state.storeID, this.state.storeName, this.state.adddress1, this.state.address2, this.state.postCode, this.state.email, this.state.mobileNo)
        }
    }

    handleChange = (e) => {
        this.setState({ [e.target.value]: e.target.value });
        this.setState({ [e.target.name]: e.target.value });
    }

    renderForm() {
        const { isLoading, storeID, storeName, adddress1, address2, postCode, email, mobileNo } = this.state;
        const { handleTitle } = this.props;
        return (<>
            {handleTitle(storeID == '0' ? 'Add Store' : 'Edit Store')}
            <form noValidate style={{ width: '500px' }}>
                <FilledTextField label={'StoreName'} rows={1} name="storeName" value={storeName} onChange={this.handleChange} style={{ marginTop: '2%' }} />
                <FilledTextField label={'Adddress1'} rows={1} name="adddress1" value={adddress1} onChange={this.handleChange} style={{ marginTop: '2%' }} />
                <FilledTextField label={'Address2'} rows={1} name="address2" value={address2} onChange={this.handleChange} style={{ marginTop: '2%' }} />
                <FilledTextField label={'PostCode'} rows={1} name="postCode" value={postCode} onChange={this.handleChange} style={{ marginTop: '2%' }} />
                <FilledTextField disabled={storeID == '0' ? false : true} label={'E-Mail'} rows={1} name="email" value={email} onChange={this.handleChange} style={{ marginTop: '2%' }} />
                <FilledTextField disabled={storeID == '0' ? false : true} label={'Mobile No'} rows={1} name="mobileNo" value={mobileNo} onChange={this.handleChange} style={{ marginTop: '2%' }} />
                <ButtonBase onClick={() => { this.handleSubmit() }} title={storeID == '0' ? 'Add Store' : 'Edit Store'} isLoading={isLoading} style={{ marginTop: '5%' }} />
            </form>
        </>
        );
    }

    renderNewForm() {
        const { StoreID, TenantID, isLoading } = this.state;
        const { handleTitle } = this.props;
        return (
            <div style={{ width: '350px', height: '220px', display: 'grid', alignContent: 'space-between' }}>
                {handleTitle('Add Store')}
                <SelectDropDown placeholder={"Select Tenant"} value={TenantID} name={"TenantID"}
                    onChange={(e) => { this.setState({ TenantID: e.target.value }); }}
                    options={this.state.dataSourceTenant} />
                <SelectDropDown placeholder={"Select Store"} value={StoreID} name={"StoreID"}
                    onChange={(e) => { this.setState({ StoreID: e.target.value }); }}
                    options={this.state.dataSourceStore} />
                <ButtonBase onClick={() => { this.wsAddStoreToTenant(TenantID, StoreID) }} title={'Add Store'} isLoading={isLoading} style={{ marginTop: '5%' }} />
            </div>
        )
    }

    renderStoreList() {
        const { UserStoreData, mediumBrowser } = this.state;
        const { classes, handleTitle } = this.props;
        return (<div style={mediumBrowser ? { width: '375px', height: "400px" } : { width: '500px', height: "400px" }}>
            {handleTitle("Stores")}
            {!UserStoreData.length
                ? <Grid container style={{ width: '100%', bottom: '0%', top: '0%', left: '0%', right: '0%' }} direction="row" justify='center' alignItems='center'>
                    <Typography style={{ fontSize: '18px', fontWeight: '600', fontFamily: 'Nunito, sans-serif', textAlign: 'center' }}> No Store Found </Typography>
                </Grid>
                : UserStoreData.map((item, index) =>
                    <div style={{ border: '1px solid #000', borderRadius: '5px', width: '100%', fontSize: "13px", maxHeight: "235px", overflowY: 'auto', marginBottom: '2px', }}>
                        <div className={!(index % 2) ? classes.oddDiv : classes.evenDiv} >
                            <div style={{ display: 'grid', color: '#000' }}>
                                <label style={{ color: '#262628', fontSize: '14px', fontWeight: '700' }}>{item.StoreName}</label>
                                <label>{item.Address1}</label>
                                <label>{item.Address2}</label>
                                <label>{item.CityName}</label>
                                <label>{item.Postcode}</label>
                                <label>{item.MobileNo}</label>
                            </div>
                            <div>
                                <IconButton style={{ padding: '8px' }} aria-label="edit" onClick={() => { this.wsGetStoreByID(item.StoreID); }}>
                                    <Edit style={{ fontSize: '18px', }} />
                                </IconButton>
                                <IconButton style={{ padding: '8px' }} aria-label="delete" onClick={() => this.wsDeleteStore(item.StoreID)}>
                                    <Delete style={{ fontSize: '18px', }} />
                                </IconButton>
                            </div>
                        </div>
                    </div>)}
        </div>);
    }

    resize() {
        this.setState({ mediumBrowser: window.innerWidth <= 920 });
    }
    LoadingView() {
        return (
            <Grid container style={{ width: '100%', padding: "100px" }} direction={'row'} justify={'center'} alignItems='center'>
                <CircularProgress />
            </Grid>
        )
    }

    render() {
        const { isEditStore, storeLoading, addNewStore } = this.state;
        if (storeLoading) { return this.LoadingView() }
        else {
            return (
                <React.Fragment>
                    {!isEditStore &&
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', }}>
                            <label style={{ fontWeight: 600, color: '#000', cursor: 'pointer' }} onClick={() => { this.setState({ isEditStore: true, addNewStore: true }) }}>Add Store</label>
                            <label style={{ fontWeight: 600, color: '#000', cursor: 'pointer' }} onClick={() => { window.open('https://chemisto.live/') }}>Add New Store</label>
                        </div>}
                    <div style={{ width: '100%', overflowY: 'auto', marginBottom: '2px', }}>
                        {!isEditStore ? this.renderStoreList() : addNewStore ? this.renderNewForm() : this.renderForm()}
                    </div>
                    <CssBaseline />
                    <ToastsContainer store={ToastsStore} />
                </React.Fragment>
            );
        }
    }
}
export default withStyles(StoresStyle)(Stores);